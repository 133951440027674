import { cnn, cnnAjax, cnnEmailPass } from "../connect/apiPuntos";

const BASE_PATH = "user";

/**
 * Guarda el usuario en la base de datos !!
 * @param {La data a registrarse en el api} body [object]
 */
export const saveRegistre = async body => {
    const resp = await cnnAjax(`${BASE_PATH}/register`, "POST", {}, body);

    return resp;
};

export const login = async body => {
    const { response, status, auth, message } = await cnnAjax(
        `${BASE_PATH}/login`,
        "POST",
        {},
        body
    );

    return { response, status, auth, message };
};

/**
 * Hace el auto login con el token temporal del home banking !!
 * @param {Object} tokenBanking {token: String} es el token que se genera por el hombe banking
 */
export const autoLoginServices = async tokenBanking => {
    const resp = await cnnAjax(
        `${BASE_PATH}/autologin`,
        "POST",
        null,
        tokenBanking
    );

    return resp;
};

/**
 * consulta en la base de registrados de home banking si el usuario exite y lo regresa
 * @param {Object} tokenBanking {token: string} el token del home banking
 */
export const userHomeBanking = async tokenBanking => {
    const resp = await cnnAjax(
        `${BASE_PATH}/homeBanking`,
        "POST",
        null,
        tokenBanking
    );

    return resp;
};

// actualiza los datos del usuario !!
export const updateProfile = async body => {
    //TODO: verificar por que no pasa los cors!!

    const data = await cnnAjax(
        `${BASE_PATH}/profile/update`,
        "POST",
        null,
        body
    );

    return data;
};

// actualiza la tarjeta
export const updateCreditCardServices = async body => {
    const { response } = await cnnAjax(
        `${BASE_PATH}/creditCard`,
        "POST",
        {},
        body
    );

    return response;
};

// valida si el token esta vigente y si existe
export const isValidTokenResetPass = async token => {
    const { response } = await cnnAjax(
        `${BASE_PATH}/valid/${token}/reset`,
        "POST"
    );
    return response;
};

// guarda la nueva contraseña
export const saveNewPassword = async (token, password) => {
    const { response } = await cnnAjax(
        `${BASE_PATH}/newpassword`,
        "POST",
        null,
        { token, password }
    );

    return response;
};

export const resetPassSendMail = async email => {
    const response = await cnnEmailPass(
        `${BASE_PATH}/reset/password/${email}`,
        "POST"
    );

    return response;
};
