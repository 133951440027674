import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
// nodejs library that concatenates classes
import classNames from "classnames";
import queryString from "query-string";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle";
import MultiCarousel from "components/MultiCarousel/MultiCarousel";
import MultiCarouselProducts from "components/MultiCarousel/MultiCarouselProducts";
import { MAS_PEDIDOS } from "config/const/constText";
import { withRouter } from "react-router";
import * as modalActions from "redux/actions/modalActions";
import { getProductsByCategoriesActive } from "services/home/productsServices";
import { getBannersPromoServices } from "services/publicidad/publicidad";
import { autoLoginServices } from "services/user/userServices";
import { capitalize } from "utils/utils";
import BannerContainer from "views/Banner/BannerContainer";
import SectionBannerPromo from "./Sections/SectionBannerPromo";
import SectionInfo from "./Sections/SectionInfo";

const useStyles = makeStyles(styles);

const LandingPage = props => {
    const classes = useStyles();

    const [productsActiveShow, setProductsActiveShow] = useState([]);
    const [bannersPromo, setBannersPromo] = useState({
        firstBanner: [],
        secondBanner: [],
        thirdBanner: []
    });

    useEffect(() => {
        const { location } = props;

        if (location.pathname === "/autologin") {
            props.toggleGlobalLoader();
            const { t } = queryString.parse(location.search);
            if (t) {
                handleAutoLogin(t);
            }
        } else if (location.search) {
            props.history.replace("/");
        }

        goTop();

        handleGetProductsActivesByCategory();
        handleGetBannerPromo();
    }, []);

    const handleGetBannerPromo = async () => {
        const result = await getBannersPromoServices();
        if (!!result?.response?.length) {
            const banners = result.response.reduce(
                (acc, el) => {
                    switch (el.seccion) {
                        case 1:
                            acc.firstBanner = [...acc.firstBanner, el];
                            break;
                        case 2:
                            acc.secondBanner = [...acc.secondBanner, el];
                            break;
                        default:
                            acc.thirdBanner = [...acc.thirdBanner, el];
                            break;
                    }

                    return acc;
                },
                { firstBanner: [], secondBanner: [], thirdBanner: [] }
            );

            setBannersPromo(banners);
        }
    };

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleGetProductsActivesByCategory = async () => {
        const result = await getProductsByCategoriesActive();

        setProductsActiveShow(result.response);
    };

    // TODO: mandar un mensaje que ocurrio un error al hacer auto login!!
    const handleAutoLogin = async token => {
        const { response } = await autoLoginServices({ token });
        props.toggleGlobalLoader();

        if (!!response) {
            props.getPointsUser({ token: response.token });
            props.setUserInfo(response);
        }
        props.history.replace("/");
    };

    const handleDetailProduct = id => {
        props.history.push(`/producto/${btoa(id)}`);
    };

    const handleGoCategory = slug => {
        props.history.push(`/tienda/${slug}?page=1`);
    };

    return (
        <div style={{ position: "relative" }}>
            <BannerContainer history={props.history} />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "10px 0"
                }}
            ></div>
            <section className={classes.sectionWhite}>
                <div
                    className={classNames(
                        classes.main,
                        classes.mainRaised,
                        classes.rewards,
                        classes.bgWhite
                    )}
                >
                    <div
                        className={classNames(classes.container, classes.mt25)}
                    >
                        <h2
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight
                            )}
                        >
                            {" "}
                            Descubre todo lo que puedes canjear
                        </h2>
                        <MultiCarousel categories />
                    </div>
                </div>
            </section>

            <section style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                <SectionBannerPromo banners={bannersPromo.firstBanner} />
            </section>

            {!!productsActiveShow.length && (
                <section
                    className={classNames(classes.bgWhite, classes.sectionGray)}
                >
                    <div className={classNames(classes.container)}>
                        <h2
                            onClick={() =>
                                handleGoCategory(productsActiveShow[0].slug)
                            }
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight,
                                classes.pointer
                            )}
                        >
                            {capitalize(productsActiveShow[0].nombre)}
                        </h2>
                        <MultiCarouselProducts
                            product={productsActiveShow[0].products}
                        />
                    </div>
                </section>
            )}

            <section className={classes.sectionGray}>
                <SectionBannerPromo banners={bannersPromo.secondBanner} />
            </section>

            {!!productsActiveShow.length && (
                <section
                    className={classNames(classes.bgWhite, classes.sectionGray)}
                >
                    <div className={classNames(classes.container)}>
                        <h2
                            onClick={() =>
                                handleGoCategory(productsActiveShow[1].slug)
                            }
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight,
                                classes.pointer
                            )}
                        >
                            {capitalize(productsActiveShow[1].nombre)}
                        </h2>
                        <MultiCarouselProducts
                            product={productsActiveShow[1].products}
                        />
                    </div>
                </section>
            )}

            <section className={classes.sectionGray}>
                <SectionBannerPromo banners={bannersPromo.thirdBanner} />
            </section>

            {!!productsActiveShow.length && (
                <section
                    className={classNames(classes.bgWhite, classes.sectionGray)}
                >
                    <div className={classNames(classes.container)}>
                        <h2
                            onClick={() =>
                                handleGoCategory(productsActiveShow[2].slug)
                            }
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight,
                                classes.pointer
                            )}
                        >
                            {capitalize(productsActiveShow[2].nombre)}
                        </h2>
                        <MultiCarouselProducts
                            product={productsActiveShow[2].products}
                        />
                    </div>
                </section>
            )}

            <div
                className={classNames(
                    classes.main,
                    classes.mainRaised,
                    classes.rewards
                )}
            >
                <div className={classNames(classes.container, classes.mt25)}>
                    <div>
                        <h3
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight,
                                classes.ptTitle
                            )}
                        >
                            Canjea lo que más deseas con tus Puntos BBVA
                        </h3>
                    </div>

                    <SectionInfo />
                    <div
                        className={classNames(
                            classes.marginSectionTop,
                            classes.preFooter
                        )}
                    >
                        <h2
                            className={classNames(
                                classes.titleCarousel,
                                classes.sizeTitle,
                                classes.textCenter,
                                classes.ffLight
                            )}
                        >
                            {" "}
                            {MAS_PEDIDOS}
                        </h2>
                        <MultiCarousel detailProduct={handleDetailProduct} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({
    ModalsReducer,
    userReducer,
    productsRecommended
}) => {
    return {
        userReducer,
        ModalsReducer,
        productsRecommended
    };
};

const mapDispatchToProps = {
    ...userActions,
    ...modalActions
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LandingPage)
);
