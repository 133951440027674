import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import * as productsActions from "../../redux/actions/productsActions";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts";

// styles
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
// import SectionProducts from '../LandingPage/Sections/SectionProducts';

const useStyles = makeStyles(shoppingCartStyle);

const SearchPage = props => {
    const classes = useStyles();
    const [search, setSearch] = useState("");

    useEffect(() => {
        const { match } = props;
        //console.log("props", props)
        if (match.params) {
            // const { s } = queryString.parse(location.search);
            const termino = match.params.s;
            setSearch(termino);
            if (props.selectProduct != termino) {
                props.searchProduct(termino);
            }
        }
        goTop();
        // handleLastRequest();
    }, [props.match.params]);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const hanleDetailProduct = id => {
        props.history.push(`/producto/${btoa(id)}`);
    };

    return (
        <div
            className={classNames(
                classes.main,
                classes.mainRaised,
                classes.mtSearch
            )}
        >
            <div className={classNames(classes.paddingVerticalSearch)}>
                <div className={classes.container}>
                    <h3>Resultado de busqueda: {search}</h3>
                </div>
            </div>

            <SectionProducts search={true} detailProduct={hanleDetailProduct} />
        </div>
    );
};

const mapStateToProps = ({ productsReducer }) => {
    return productsReducer;
};

const mapDispatchToProps = {
    ...productsActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchPage);
