import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Delete from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
// nodejs library that concatenates classes
import classNames from "classnames";

// styles
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import styleTool from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";

// icons !!
import SvgIcon from "components/Svg/SvgIcon";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Icon } from "@material-ui/core";
import { getDepartamento } from "services/form/direccionServices";
import { getDistrito } from "services/form/direccionServices";
import { getProvincia } from "services/form/direccionServices";
import { NO_TIPE_DIRECTION } from "config/const/constText";
import { TIPO_DIRECTION } from "config/const/constText";

const useStyles = makeStyles(styles);
const useStylesTool = makeStyles(styleTool);

const CardDirection = ({
    direction,
    selectDirection,
    selectEditDirection,
    directionSelected,
    removeDirection,
    user,
    userID,
    onClick
}) => {
    console.log("CardDirection", direction)
    // const [departamento, setDepartamento] = useState(null);
    // const [provincia, setProvincia] = useState(null);
    // const [distrito, setDistrito] = useState(null);

    const classes = useStyles();
    const classesToolTip = useStylesTool();

    useEffect(() => {
        // handleInitialRequest();
    }, []);

    // const handleInitialRequest = async () => {
    //     await handleDepartamentoById(direction.idDepa);
    //     await handleProvinciaById(direction.idProv);
    //     await handleDistritoById(direction.idDist);
    // };

    // const handleDepartamentoById = async id => {
    //     const { response } = await getDepartamento(id);
    //     console.log("handleDepartamentoById", response)
    //     setDepartamento(response.departamento);
    // };

    // const handleProvinciaById = async id => {
    //     const { response } = await getProvincia(id);
    //     setProvincia(response.provincia);
    // };

    // const handleDistritoById = async id => {
    //     if (!id) return;
    //     const { response } = await getDistrito(id);
    //     setDistrito(response.distrito);
    // };

    const handleSetEditDirection = () => {
        handleSetDirection(direction);

        selectEditDirection(direction);
        if (onClick && typeof onClick === "function") {
            onClick();
        }
    };

    // const handleSelectDirection = () => {
    //     selectDirection(direction);
    // };

    // pasa por referencia !!
    const handleSetDirection = () => {
        // direction.id_usuario = userID;

        direction.departamento = direction.idDepa;
        delete direction.idDepa;

        direction.distrito = direction.idDist;
        delete direction.idDist;

        direction.provincia = direction.idProv;
        delete direction.idProv;
    };

    const handleIsDisabled = () => {
        if (!directionSelected) return false;

        return directionSelected.id === direction.id;
    };

    return (
        <Card>
            <CardBody>
                <div className={classes.headerAcordeon}>
                    <Danger className={classes.flex}>
                        <SvgIcon
                            desc="icono-home"
                            fill="#f44336"
                            width="20px"
                            height="20px"
                        />
                        <h6 className={classNames(classes.headerTag)}>
                            {direction.etiqueta || TIPO_DIRECTION}
                        </h6>
                    </Danger>

                    <Tooltip
                        title="Eliminar Dirección"
                        placement="left"
                        classes={{
                            tooltip: classesToolTip.tooltip
                        }}
                    >
                        <Button
                            justIcon
                            simple
                            onClick={() =>
                                removeDirection(direction.id, user.token)
                            }
                        >
                            <SvgIcon desc="icono-delete" fill="#f44336" />
                        </Button>
                    </Tooltip>
                </div>
                <div>
                    <h6 className={classes.cardCategory}>Dirección</h6>
                    <p className={classes.cardDescription}>
                        {" "}
                        {direction.direccion || NO_TIPE_DIRECTION}{" "}
                    </p>
                </div>
                <div>
                    <h6 className={classes.cardCategory}>Referencia</h6>
                    <p className={classes.cardDescription}>
                        {" "}
                        {direction.referencia}{" "}
                    </p>
                </div>
                <GridContainer>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Teléfono movil</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.telefono_movil}{" "}
                        </p>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Teléfono fijo</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.telefono_fijo}{" "}
                        </p>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Contacto</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.contacto}{" "}
                        </p>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Departamento</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.path.departamento}{" "}
                        </p>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Provincia</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.path.provincia}{" "}
                        </p>
                    </GridItem>
                    <GridItem xs={12} md={6} sm={12}>
                        <h6 className={classes.cardCategory}>Distrito</h6>
                        <p className={classes.cardDescription}>
                            {" "}
                            {direction.path.distrito}{" "}
                        </p>
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter>
                <Button color="info" size="sm" onClick={handleSetEditDirection}>
                    Editar
                </Button>
            </CardFooter>
        </Card>
    );
};

export default CardDirection;
