import axios from "axios";
import $ from "jquery";
import config from "../../config/config.json";

/* prueba cuando pasa la validacion de cors !!
 *  Esta funcion se creo por que con [axios], no pasaba la validacion de los cors !!
 */
export const cnnAjax = async (path, method, headers = null, data = null) => {
    headers = setHeaders(headers);

    const options = setOptions(
        `${config.apipuntosqa}${path}`,
        method,
        headers,
        data
    );

    try {
        return await $.ajax({
            ...options,
            success: data => data,
            error: err => console.warn("error")
        });
    } catch (error) {
        return { response: null, status: "error", message: error };
    }
};

/**
 * Esta funcion realiza la coneccion al api me puntos !!
 * @param {El path de la url al que se apunta} path [string]
 * @param {El metodo que se usara para el llamado} method [string]
 * @param {Los headers que se mandaran al api} headers [object]
 * @param {Payload que se manda al api} data [object]
 */
export const cnn = async (path, method, headers = null, data = null) => {
    headers = setHeaders(headers);

    const options = setOptions(
        `${config.apipuntosqa}${path}`,
        method,
        headers,
        data
    );

    try {
        return await axios(options);
    } catch (error) {
        return { response: null, status: "error", message: error };
    }
};

// agrega los nuevo headers a la peticion !!
const setHeaders = headers => {
    return !!headers ? { ...config.headers, ...headers } : config.headers;
};

const setOptions = (url, method, headers, data) => {
    if (!!data) {
        return { url, method, headers, data };
    }
    return { url, method, headers };
};

/* prueba cuando pasa la validacion de cors !!
 *  Esta funcion se creo por que con [axios], no pasaba la validacion de los cors !!
 */
export const cnnEmailPass = async (
    path,
    method,
    headers = null,
    data = null
) => {
    headers = setHeaders(headers);

    const options = setOptions(
        `${config.apipuntosqa}${path}`,
        method,
        headers,
        data
    );

    try {
        return await $.ajax({
            ...options,
            success: data => data,
            error: err => console.warn("error")
        });
    } catch (error) {
        return { response: null, status: "error", message: error };
    }
};
