import React, { useEffect, useState } from "react";
import Button from "components/CustomButtons/Button.js";
import Home from "@material-ui/icons/Home";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import checkoutStyle from "assets/jss/material-kit-pro-react/views/checkoutPage.js";
import WrapperMovile from "hoc/WrapperMovile";

//redux
import { connect } from "react-redux";
import * as visaCashActions from "../../redux/actions/visaCashActions";

import FormVisa from "views/Checkout/FormVisa/FormVisa";
import { ModalLoader } from "components/Modal/ModalLoader";
import { setPointsStorage } from "services/localStorage/dataChangePoints";
import Danger from "components/Typography/Danger";

const useStylesCheckout = makeStyles(checkoutStyle);

// REFACTOR: esta en hardcode, solo en caso de exito!!
const RespuestaCanjePage = props => {
    const classesCheck = useStylesCheckout();

    const [amount, setAmount] = useState(0);
    const [purchaseNumber, setPurchaseNumber] = useState("0");
    const [listenerForm, setListenerForm] = useState(true);

    useEffect(() => {
        handleIsShowModal();

        const {
            location: { state }
        } = props;

        setPurchaseNumber(state.numPedido);

        setAmount(handleSetStructMount(state.amount));

        if (listenerForm) {
            // var interval = setInterval(() => {
            handleShowModal();
            // }, 2000);
        }
        // else {
        //     clearInterval(interval)
        // }

        // let interval = setInterval(() => {
        //     let frame = document.getElementById("visaNetJS");
        //     console.log("ciclo", frame)
        //     if (frame) {
        //         console.log("hay frame", frame)
        //     }
        // }, 1000);

        // return () => {
        //     clearInterval(interval)
        // }

        goTop();
    }, []);

    // TRABAJANDO:
    // useEffect(() => {
    //     let frame = document.getElementById("visaNetJS");
    //     if (frame) {
    //         console.log("frame", frame)
    //     }

    // }, [document.getElementById("visaNetJS")])

    useEffect(() => {
        if (props.data) {
            const {
                location: { state }
            } = props;
            let data = {
                auth: props.data.token,
                purchaseNumber: state.numPedido,
                numPedidoDB: state.numPedidoDB,
                amount: handleSetStructMount(state.amount)
            };

            setPointsStorage(data);
        }
    }, [props.data]);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleIsShowModal = () => {
        const interval = setInterval(() => {
            const visa = document.getElementById("visaNetJS");
            if (visa) {
                visa.onload = function() {
                    setListenerForm(false);
                    clearInterval(interval);
                };
            }
        }, 1000);
    };

    // agrega la estructura que necesita el modal de visa !!
    const handleSetStructMount = mount => {
        let newAmount = "0.00";
        if (!mount.includes(".")) {
            newAmount = mount += ".00";
        }

        newAmount = mount.includes(",") ? mount.replace(",", "") : mount;

        return newAmount;
    };

    const handleShowModal = () => {
        const interval = setInterval(() => {
            let btn = document.getElementsByClassName("start-js-btn");
            if (!!btn.length) {
                btn[0].click();
                btn[0].addEventListener("click", function() {
                    // setListenerForm(false);
                });

                // setListenerForm(false);
                clearInterval(interval);
            }
        }, 1000);
    };

    const handleGoHome = () => {
        props.history.replace("/");
    };

    return (
        <WrapperMovile>
            <div
                className={classNames(
                    classesCheck.main,
                    classesCheck.mainRaised,
                    classesCheck.mainMarginTop,
                    classesCheck.heigthSizeContainer
                )}
            >
                <div
                    className={classNames(
                        classesCheck.container,
                        classesCheck.resetPd,
                        classesCheck.mt6,
                        classesCheck.paddingHorizontal5
                    )}
                >
                    <ModalLoader showModal={listenerForm} />

                    <h3
                        className={classNames(
                            classesCheck.fz20,
                            classesCheck.paddingTitle
                        )}
                    >
                        Espera mientras procesamos el pedido de tus Puntos BBVA,{" "}
                        <Danger>
                            {" "}
                            <h3 className={classesCheck.fz20}>
                                <b>No cierres el navegador.</b>
                            </h3>
                        </Danger>
                    </h3>

                    <h3
                        className={classNames(
                            classesCheck.fz20,
                            classesCheck.pt10
                        )}
                    >
                        Si realizaste un canje de Puntos más Soles, se te
                        solicitarán los datos de tu Tarjeta.
                    </h3>

                    {/* <Button color="bbva" onClick={handleGoHome}>
                    <Home></Home>
                    Regresar al Inicio
                </Button> */}

                    {props.data && amount != 0 && (
                        <FormVisa // formulario de visa !!
                            sessionKey={props.data.session.sessionKey}
                            purchaseNumber={purchaseNumber}
                            amount={amount}
                        />
                    )}
                </div>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({ visaCashReducer }) => {
    return visaCashReducer;
};

const mapStateToDispatch = {
    ...visaCashActions
};

export default connect(
    mapStateToProps,
    mapStateToDispatch
)(RespuestaCanjePage);
