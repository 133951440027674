/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Tune from "@material-ui/icons/Tune";
import CreditCard from "@material-ui/icons/CreditCard";
import Timeline from "@material-ui/icons/Timeline";

// @material-ui/icons
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
//redux!!
import { connect } from "react-redux";
import * as carActions from "../../redux/actions/carActions";
import * as modalActions from "redux/actions/modalActions";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Discount from "components/Card/Discount";

// styles
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import checkoutStyle from "assets/jss/material-kit-pro-react/views/checkoutPage.js";

import noImagen from "../../assets/img/no-image.svg";
import { numberFormat } from "utils/utils";
import Danger from "components/Typography/Danger";
import CardFooter from "components/Card/CardFooter";
import { PUNTOS } from "config/const/constText";
import CustomInput from "components/CustomInput/CustomInput";
import {
    replaceCharacter,
    transforFormatNumber,
    capitalize
} from "utils/utils";
import Cyan from "components/Typography/Cyan";
import SvgIcon from "components/Svg/SvgIcon";
import WrapperMovile from "hoc/WrapperMovile";
import { getProductsByIds } from "services/home/productsServices";
import FixedBottom from "components/SectionFixed/FixedBottom";
import { MAX_AMOUNT_ALLOWED } from "config/const/constText";

const useStyles = makeStyles(shoppingCartStyle);
const useStylesCheck = makeStyles(checkoutStyle);

const ShoppingCartPage = props => {
    const [showFixedBottom, setShowFixedBottom] = useState(false);
    const [userPoints, setUserPoints] = useState(0); // los puntos que tiene el usuario
    const [puntosInput, setPuntosInput] = useState(0); // los puntos que el usuario ingresa para canjear !!
    const [subtotal, setSubtotal] = useState(0);
    const [subtotalSoles, setSubtotalSoles] = useState(0);
    const [compareItem, setCompareItem] = useState(true);

    // classes !!
    const classes = useStyles();
    const classesCheck = useStylesCheck();

    useEffect(() => {
        function updateSize() {
            // trabajando en mostrar la tarjeta o el fixed !!
            if (window.innerWidth <= 992) {
                setShowFixedBottom(true);
            } else {
                setShowFixedBottom(false);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, [window.innerWidth]);

    useEffect(() => {
        calculateTotal();
        if (compareItem) {
            handleCheckItemsActives();
        }
    }, [props.items]);

    useEffect(() => {
        handleInitialPointsUser();
        goTop();
    }, []);

    useEffect(() => {
        handleInitialPointsUser();
    }, [props.loadingPoints]);

    useEffect(() => {
        handleInitialPointsUser();
    }, [subtotal]);

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleCheckItemsActives = async () => {
        const newItems = props.items.map(v => v.id);

        if (!!newItems.length) {
            const activeItems = await getProductsByIds({ ids: newItems });

            if (activeItems && activeItems.status != "error") {
                setCompareItem(false);
                // const eliminados = props.items.findIndex(v => { console.log(v); return true });
                const rt = activeItems.map(product => {
                    const newProduct = props.items.find(
                        item => item.id == product.id
                    );

                    if (!!newProduct) {
                        newProduct.precioFinal = handleFinalPrice(
                            product,
                            newProduct.cantidad
                        );
                        newProduct.costoenvio = Number(
                            numberFormat(product.costoenvio)
                        );
                        newProduct.precioOferta =
                            product.preciooferta > 0 ? product.preciooferta : 0;
                        newProduct.puntos = product.puntos;
                        newProduct.precio =
                            product.preciooferta > 0
                                ? product.preciooferta
                                : product.precio;
                        newProduct.puntosOferta =
                            product.puntosoferta > 0 ? product.puntosoferta : 0;

                        newProduct.costoenviovale =
                            product?.costoenviovale || 0;
                        return newProduct;
                    }
                });
                // console.log("actives", activeItems, newItems)
                // // TRABAJANDO:

                // console.log("resultado", result);
                props.setProductsCheckout(rt);
            }
        }
    };

    const handleFinalPrice = (product, cant) => {
        if (product.puntosoferta > 0) {
            return product.puntosoferta * cant;
        }

        return product.puntos * cant;
    };

    const calculateTotal = () => {
        if (!props.items.length) {
            setSubtotal(0);
            setSubtotalSoles(0);
            return;
        }
        const subTotal = numberFormat(props.items.reduce(calculatePuntos, 0));
        setSubtotal(subTotal);

        const subTotalSoles = numberFormat(
            props.items.reduce(calculateSoles, 0)
        );
        setSubtotalSoles(subTotalSoles);
    };

    /** @OJO esta funcion es una [deduce]!!
     * suma y regresa el todal a pagar en puntos !!
     * @param {number} acc El acumulador
     * @param {object} el el objeto del carrito
     */
    const calculatePuntos = (acc, el) => {
        if (!!el.puntosOferta) {
            return acc + el.puntosOferta * el.cantidad;
        }
        return acc + el.puntos * el.cantidad;
    };

    /** @OJO esta funcion es una [deduce]!!
     * suma y regresa el todal a pagar en soles !!
     * @param {number} acc El acumulador
     * @param {object} el el objeto del carrito
     */
    const calculateSoles = (acc, el) => {
        if (!!el.puntosOferta) {
            return acc + el.precioOferta * el.cantidad;
        }
        return acc + el.precio * el.cantidad;
    };

    const setPrice = item => {
        return !!item.puntosOferta ? item.puntosOferta : item.puntos;
    };

    const handlePriceSoles = item => {
        if (item.precioOferta > 0) {
            return item.precioOferta;
        }
        return item.precio;
    };

    //regresa el array con la estructura que se necesita para mostrarse en la tabla !!
    const handleTableData = () => {
        let table = [];
        const arrItems = showItems();
        arrItems.map(values => table.push(values));
        // table.push(tableFooter());
        return table;
    };

    const handleInitialPointsUser = () => {
        if (!props.user.puntos || props.user.puntos == 0) {
            setUserPoints(0);
            return;
        }
        // debugger;

        const puntosUsuario = parseFloat(
            replaceCharacter(props.user.puntos, ",", "")
        );

        let subTotal = subtotal;
        if (subTotal != 0) {
            subTotal = parseFloat(replaceCharacter(subTotal, ",", ""));
        }

        let calculo = puntosUsuario - 1;

        if (calculo > subTotal) {
            setPuntosInput(subTotal);
        } else {
            setPuntosInput(calculo);
        }

        calculo = transforFormatNumber(calculo);
        setUserPoints(calculo || 0);
    };

    // calcula de la canja de texto de los puntos a pagar, cuando se cambia la caja de texto !!
    const handleChangeTotalPoints = e => {
        let { value } = e.target;
        value = !!value.length ? parseFloat(value) : value;
        const userPoint = parseFloat(replaceCharacter(userPoints, ",", ""));

        let newValue = value;
        if (value >= userPoint && userPoint != 0) {
            newValue = userPoint;
        } else if (userPoint == 0) {
            newValue = 0;
        } else if (value > subtotal) {
            newValue = subtotal;
        }

        setPuntosInput(newValue);
    };

    // el calculo del total que se muestra en la tarjeta que calcula el resultado!!
    const handleCash = () => {
        if (!subtotal || subtotal == 0 || !puntosInput || puntosInput == 0) {
            return subtotalSoles;
        }
        const puntoTotal = replaceCharacter(subtotal, ",", "");
        const puntosUser = replaceCharacter(puntosInput, ",", "");
        const calculo = (puntoTotal - puntosUser) / 33;
        return transforFormatNumber(calculo);
    };

    const handleGoCheckout = () => {
        const { history } = props;
        history.push("/checkout", {
            point: puntosInput
        });
    };

    // si el usuario no esta logueado, levanta el modal de login.
    const handleMiddlewareUser = () => {
        if (!Object.keys(props.user).length) {
            props.toggleLoginModal();
            return;
        }
        if (props.cant != 0) {
            handleGoCheckout();
        }
    };

    const cardCalculator = () => {
        return (
            <Card
                color="info"
                className={classNames(
                    classes.pdVerticalCard,
                    classes.mb0,
                    showFixedBottom ? classes.mt4 : ""
                )}
            >
                <CardBody color>
                    <div className={classesCheck.containerTotal}>
                        <div className={classesCheck.displayFlex}>
                            <Timeline></Timeline>
                            <p
                                className={classNames(
                                    classesCheck.pl05,
                                    classes.lightWhite
                                )}
                            >
                                ¿Cuanto pagarás con tu tarjeta?
                            </p>
                        </div>
                        <div className={classesCheck.displayFlex}>
                            <p
                                className={classNames(
                                    classes.textWhite,
                                    classesCheck.pl05,
                                    classesCheck.m0
                                )}
                            >
                                Ingresa los Puntos BBVA que utilizaras para
                                calcular el saldo a pagar con tu tarjeta.
                            </p>
                        </div>
                        <div className={classesCheck.containerCalculate}>
                            <div className={classesCheck.totalTextLeft}>
                                <Tune></Tune>
                                <p
                                    className={classNames(
                                        classesCheck.pl05,
                                        classesCheck.m0,
                                        classes.lightWhite
                                    )}
                                >
                                    {" "}
                                    Utilizar
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    classesCheck.displayFlex,
                                    classesCheck.flex
                                )}
                            >
                                <CustomInput
                                    id="puntos"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        className: classes.textWhite,
                                        placeholder: "Total puntos",
                                        type: "text",
                                        name: "total_puntos",
                                        onChange: handleChangeTotalPoints,
                                        value: puntosInput,
                                        endAdornment: (
                                            <span
                                                className={classNames(
                                                    classes.textWhite,
                                                    classes.ffBook,
                                                    classes.fz12
                                                )}
                                            >
                                                Puntos
                                            </span>
                                        )
                                    }}
                                />
                            </div>
                        </div>

                        <div className={classesCheck.containerCalculate}>
                            <div className={classesCheck.totalTextLeft}>
                                <CreditCard></CreditCard>
                                <p
                                    className={classNames(
                                        classesCheck.pl05,
                                        classesCheck.m0,
                                        classes.lightWhite
                                    )}
                                >
                                    Paga con tarjeta
                                </p>
                            </div>
                            <div>
                                <span className={classes.ffBook}>
                                    S/{handleCash()}
                                </span>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    };

    const handleInfoEnvio = envio => {
        return envio === "L" ? (
            <>
                {" "}
                <SvgIcon
                    desc="icono-delivery"
                    fill="#626262"
                    width="17px"
                    height="17px"
                />
                &nbsp;{`Solo Lima y Callao`}
            </>
        ) : (
            <>
                {" "}
                <AirplanemodeActive className={classes.fz16} />{" "}
                {" Envío Nacional"}
            </>
        );
    };

    const showItemsMobile = () => {
        return props.items.map((item, i) => (
            <GridContainer className={classes.separator} key={`car-${i}`}>
                <GridItem
                    xs={5}
                    sm={3}
                    md={3}
                    className={classNames(
                        classes.flexCenter,
                        classes.directionColumn
                    )}
                >
                    <div
                        className={classNames(
                            classes.imgContainerMobile,
                            classes.flexCenter
                        )}
                    >
                        <img
                            src={item.imagen}
                            alt={item.titulo}
                            className={classes.img}
                            onError={e => (e.target.src = noImagen)}
                        />
                        {item.puntosOferta > 0 && (
                            <Discount
                                puntos={item.puntos}
                                offer={item.puntosOferta}
                            />
                        )}
                    </div>
                    <div className={classes.flexCenter}>
                        <div className={classes.buttonGroup}>
                            <Button
                                color="info"
                                size="sm"
                                outline
                                roundFullMobile
                                onClick={() => props.subtractQuantityItem(item)}
                                disabled={item.cantidad <= 1 ? true : false}
                            >
                                <Remove />
                            </Button>
                        </div>
                        <span className={classes.pdLefRig05}>
                            {` ${item.cantidad} `}
                        </span>

                        <div className={classes.buttonGroup}>
                            <Button
                                color="info"
                                size="sm"
                                outline
                                roundFullMobile
                                disabled={item.cantidad >= MAX_AMOUNT_ALLOWED}
                                onClick={() => {
                                    item.cantidad = 1;
                                    props.addToCar(item);
                                }}
                            >
                                <Add />
                            </Button>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={7} sm={9} md={9}>
                    {/* REFACTOR: */}
                    <Link
                        to={`/producto/${btoa(item.id)}`}
                        state={{
                            product: item.marca
                        }}
                        className={classes.tdNameAnchor}
                    >
                        {capitalize(item.marca)}
                    </Link>

                    <div
                        className={classNames(
                            classes.tdNameSmall,
                            classes.fz12,
                            classes.ffBook
                        )}
                    >
                        {item.titulo}
                    </div>
                    {item.puntosOferta > 0 && (
                        <div
                            className={classNames(
                                classes.fz11,
                                classes.priceOffert
                            )}
                        >
                            {numberFormat(item.puntos)}
                            {" " + PUNTOS}
                        </div>
                    )}
                    <div className={classes.fz12}>
                        {numberFormat(setPrice(item))} {PUNTOS}
                    </div>
                    <p
                        className={classNames(
                            classes.ffBook,
                            classes.oPorSmall
                        )}
                    >
                        o S/{handlePriceSoles(item)}
                    </p>
                    <p className={classes.centerEnvio}>
                        {handleInfoEnvio(item.envio)}
                    </p>
                    <Danger
                        className={classNames(classes.fz12, classes.pointer)}
                        onClick={() => props.removeItemById(item.id)}
                    >
                        Eliminar
                    </Danger>
                </GridItem>
            </GridContainer>
        ));
    };

    // llena las filas de la tabla con lo necesario !!
    const showItems = () => {
        const newItems = props.items.map((item, i) => {
            return [
                <div className={classes.imgContainer} key={`car-${i}`}>
                    <img
                        src={item.imagen}
                        alt={item.titulo}
                        className={classes.img}
                        onError={e => (e.target.src = noImagen)}
                    />
                    {item.puntosOferta > 0 && (
                        <Discount
                            puntos={item.puntos}
                            offer={item.puntosOferta}
                        />
                    )}
                </div>,
                <>
                    {/* REFACTOR: */}
                    <div>
                        <Link
                            to={`/producto/${btoa(item.id)}`}
                            state={{
                                product: item.marca
                            }}
                            className={classes.tdNameAnchor}
                        >
                            {capitalize(item.marca)}
                        </Link>
                    </div>

                    <small
                        className={classNames(
                            classes.tdNameSmall,
                            classes.ffBook
                        )}
                    >
                        {item.titulo}
                    </small>
                    <p className={classes.centerEnvio}>
                        {handleInfoEnvio(item.envio)}
                    </p>
                </>,
                <>
                    {item.puntosOferta > 0 && (
                        <div
                            className={classNames(
                                classes.fz11,
                                classes.priceOffert
                            )}
                        >
                            {numberFormat(item.puntos)}
                            {" " + PUNTOS}
                        </div>
                    )}
                    <span className={classes.tdPointsSmnall}>
                        {numberFormat(setPrice(item))} {PUNTOS}
                        {/* <small className={classes.tdNumberSmall}></small> */}
                    </span>
                    <p
                        className={classNames(
                            classes.ffBook,
                            classes.oPorSmall
                        )}
                    >
                        o S/{handlePriceSoles(item)}
                    </p>
                </>,
                <span>
                    <div className={classes.buttonGroup}>
                        <Button
                            color="info"
                            size="sm"
                            outline
                            roundFull
                            onClick={() => props.subtractQuantityItem(item)}
                            disabled={item.cantidad <= 1 ? true : false}
                        >
                            <Remove />
                        </Button>
                    </div>
                    <span className={classes.pdLefRig05}>
                        {` ${item.cantidad} `}
                    </span>

                    <div className={classes.buttonGroup}>
                        <Button
                            color="info"
                            size="sm"
                            outline
                            roundFull
                            disabled={item.cantidad >= MAX_AMOUNT_ALLOWED}
                            onClick={() => {
                                item.cantidad = 1;
                                props.addToCar(item);
                            }}
                        >
                            <Add />
                        </Button>
                    </div>
                </span>,

                <Tooltip
                    id="close1"
                    title="Elimina el item"
                    placement="left"
                    classes={{
                        tooltip: classes.tooltip
                    }}
                >
                    <Button
                        link
                        className={classes.actionButton}
                        onClick={() => props.removeItemById(item.id)}
                    >
                        {/* <Icon>delete</Icon> */}

                        <SvgIcon desc="icono-delete" fill="#626262" />
                    </Button>
                </Tooltip>
            ];
        });

        return newItems;
    };

    const structTabelDesktop = () => {
        return (
            <Table
                tableHead={["", "Producto", "Puntos o soles", "Cantidad", ""]}
                tableData={handleTableData()}
                tableShopping
                customHeadCellClasses={[
                    classes.textCenter,
                    classes.description,
                    classes.textCenter,
                    classes.textRight,
                    classes.textRight
                ]}
                customHeadClassesForCells={[0, 2, 3, 4, 5]}
                customCellClasses={[
                    classes.tdName,
                    classes.customFont,
                    classes.tdNumber,
                    classes.cellDelete,

                    classes.customFont
                ]}
                customClassesForCells={[1, 2, 3, 4, 5]}
            />
        );
    };

    // no se esta usando el footer !!
    const tableFooter = () => {
        return {
            purchase: true,
            colspan: "3",
            amount: (
                <span>
                    <small>€</small>2,346
                </span>
            ),
            col: {
                colspan: 3,
                text: (
                    <Button color="info" round>
                        Completar la compra <KeyboardArrowRight />
                    </Button>
                )
            }
        };
    };

    return (
        <WrapperMovile>
            <div
                className={classNames(
                    classes.section,
                    showFixedBottom ? classes.paddingBottomMobile : "",
                    classesCheck.pt1
                )}
            >
                <div className={classes.container}>
                    <GridContainer className={classNames(classes.mt4)}>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={!!showFixedBottom ? 12 : 8}
                            lg={8}
                            className={classes.noPaddingHorizontal}
                        >
                            <h3
                                className={classNames(
                                    classes.cardTitleMobile,
                                    !showFixedBottom ? classesCheck.pt15 : "",
                                    classes.mt0
                                )}
                            >
                                Bolsa de pedidos
                            </h3>
                            {showFixedBottom
                                ? showItemsMobile()
                                : structTabelDesktop()}
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                            {!showFixedBottom && (
                                <Card color="white">
                                    <CardBody
                                        color
                                        className={classNames(
                                            classes.cardPrice,
                                            classes.flexCenter,
                                            classes.spaceBtw
                                        )}
                                    >
                                        <h4
                                            className={classNames(
                                                classes.cardTitle,
                                                classes.mt0
                                            )}
                                        >
                                            SubTotal
                                        </h4>
                                        <div
                                            className={classes.lineHeightPuntos}
                                        >
                                            <p
                                                className={classNames(
                                                    classes.m0,
                                                    classes.tdPointsSmnall,
                                                    classes.ffMedium
                                                )}
                                            >
                                                {" "}
                                                {subtotal} {PUNTOS}
                                            </p>
                                            <span
                                                className={classNames(
                                                    classes.textMuted,
                                                    classes.fz12,
                                                    classes.ffBook
                                                )}
                                            >
                                                o S/{subtotalSoles}
                                            </span>
                                        </div>
                                    </CardBody>
                                    <CardFooter className={classes.wrap}>
                                        <Button
                                            fullWidth
                                            block
                                            color="bbva"
                                            onClick={handleMiddlewareUser}
                                        >
                                            Continuar
                                        </Button>
                                        <p>
                                            <small
                                                className={classes.textMuted}
                                            >
                                                El costo de envío será calculado
                                                en los siguientes pasos.
                                            </small>
                                        </p>
                                    </CardFooter>
                                </Card>
                            )}
                            {/* {cardCalculator()} */}
                            {showFixedBottom && (
                                <p>
                                    <small className={classes.textMuted}>
                                        El costo de envío será calculado en los
                                        siguientes pasos.
                                    </small>
                                </p>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
            {showFixedBottom && (
                <FixedBottom
                    classBody={classNames(
                        classes.cardPrice,
                        classes.alignCenter,
                        classes.flexStart
                    )}
                    
                >
                    <div>
                        <p
                            className={classNames(
                                classes.textWhite,
                                classes.m0
                            )}
                        >
                            Total por{" "}
                            <span className={classes.badge}>
                                {props.items.length || 0}
                            </span>{" "}
                            productos
                        </p>
                        <p className={classes.m0}>
                            <Cyan>
                                {" "}
                                {subtotal} {PUNTOS}{" "}
                            </Cyan>
                        </p>
                        <p
                            className={classNames(
                                classes.ffBook,
                                classes.fz12,
                                classes.textWhite,
                                classes.oPorSmall
                            )}
                        >
                            o S/{subtotalSoles}
                        </p>
                    </div>
                    <Button
                        color="bbva"
                        size="lg"
                        onClick={handleMiddlewareUser}
                        className={classes.ml16}
                    >
                        Continuar
                    </Button>
                </FixedBottom>
            )}
        </WrapperMovile>
    );
};

const mapStateToProps = ({ carReducer, userReducer, ModalsReducer }) => {
    return {
        ...carReducer,
        user: userReducer.user,
        loadingPoints: userReducer.isLoadingPoints,
        ModalsReducer
    };
};

const mapDispatchToProps = {
    ...carActions,
    ...modalActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartPage);
