import React, { useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tune from "@material-ui/icons/Tune";
import CreditCard from "@material-ui/icons/CreditCard";
import Timeline from "@material-ui/icons/Timeline";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Info from "components/Typography/Info";
import CardFooter from "components/Card/CardFooter";
import ListDirections from "components/Table/ListDirections";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

//style
import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import checkoutStyle from "assets/jss/material-kit-pro-react/views/checkoutPage.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

// redux
import { connect } from "react-redux";
import * as modalActions from "../../redux/actions/modalActions";
import * as visaCashActions from "../../redux/actions/visaCashActions";
import * as directionsUserActions from "../../redux/actions/directionsUserActions";
import * as carActions from "../../redux/actions/carActions";
import * as userActions from "../../redux/actions/userActions";

// functions !!
import {
    replaceCharacter,
    transforFormatNumber,
    numberFormat
} from "utils/utils";
import ModalFormNewDirection from "components/Modal/ModalFormNewDirection";
import { setCanjePuntos } from "services/visaPoints/visaPointsServices";
import { setCanjeServices } from "services/canje/canjeServices";
import { ModalLoader } from "components/Modal/ModalLoader";
import { SOLES, MIXTO, PUNTOS } from "config/const/typesCanjes";
import WrapperMovile from "hoc/WrapperMovile";
import { setAuthStorage } from "services/localStorage/authStorage";
import { getPathComplete } from "services/form/direccionServices";
import NotSent from "./NotSent/NotSent";
import { capitalize } from "utils/utils";
import { removeItemsProvincia } from "services/localStorage/carStorage";
import {
    Tooltip,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    Icon
} from "@material-ui/core";
import ModalGericInfo from "components/Modal/ModalGericInfo";
import { updateAuthPoints } from "services/canje/canjeServices";
import { getProductsByIds } from "services/home/productsServices";
import { createTramaSoles } from "services/visaCash/visaCashServices";
// import { annulmentPoints } from "services/visaPoints/visaPointsServices";
import { getPoints } from "services/visaPoints/visaPointsServices";
import { PUNTOS_DELIVERY } from "config/const/constText";
import { searchCupon } from "services/cupon/copunServices";
import SvgIcon from "components/Svg/SvgIcon";
import Danger from "components/Typography/Danger";

const useStyles = makeStyles(shoppingCartStyle);
const useStylesCheckout = makeStyles(checkoutStyle);
const useStylesCheckd = makeStyles(signupPageStyle);

// internal component !!
const ItemsCard = ({
    data,
    directions,
    calculate,
    costoEnvio,
    constoEnvioVales
}) => {
    const classesCheck = useStylesCheckout();
    const handleGetShippingCash = points => {
        if (!points) {
            return 0;
        }

        return points / 33;
    };

    const itemsCar = data.map(item => (
        <div
            className={classNames(
                classesCheck.containerCard,
                classesCheck.row,
                classesCheck.borderBt
            )}
        >
            <div>
                <p className={classesCheck.m0}>
                    {" "}
                    <small> {item.titulo}</small>
                </p>
                <p className={classesCheck.m0}>
                    {" "}
                    <small> {item.marca}</small>
                </p>
                <p className={classesCheck.m0}>
                    <small>Cantidad: {item.cantidad}</small>
                </p>
            </div>
            <div className={classesCheck.sizePoints}>
                {numberFormat(item.puntos * item.cantidad)} Puntos
                <div
                    className={classNames(
                        classesCheck.sizePoints,
                        classesCheck.textR,
                        classesCheck.lineHeightNormal
                    )}
                >
                    o S/{item.precio}
                </div>
            </div>
        </div>
    ));

    if (!!directions.directions.length) {
        const selected = directions.directions.find(d => d.active == "1");
        if (!selected) return itemsCar;
        let calculoCostoEnvio = costoEnvio || selected.valor || 0;

        if (!!data.length && data.every(product => product.idcategoria === 19)) {
            calculoCostoEnvio = 0;
        }

        calculate();

        itemsCar.push(
            <div
                className={classNames(
                    classesCheck.containerCard,
                    classesCheck.row,
                    classesCheck.borderBt
                )}
            >
                <div>Costo de envío</div>
                <div>
                    {calculoCostoEnvio} Puntos
                    <div
                        className={classNames(
                            classesCheck.textR,
                            classesCheck.lineHeightNormal
                        )}
                    >
                        o S/{handleGetShippingCash(calculoCostoEnvio)}
                    </div>
                </div>
            </div>
        );
        
        if (constoEnvioVales > 0) {
            itemsCar.push(
                <div
                    className={classNames(
                        classesCheck.containerCard,
                        classesCheck.row,
                        classesCheck.borderBt
                    )}
                >
                    <div>Fee de procesamiento</div>
                    <div>
                        {constoEnvioVales} Puntos
                        <div
                            className={classNames(
                                classesCheck.textR,
                                classesCheck.lineHeightNormal
                            )}
                        >
                            o S/{handleGetShippingCash(constoEnvioVales)}
                        </div>
                    </div>
                </div>
            );
        }
    }

    return itemsCar;
};

// direcciones del usuario !!
const DirectionActiveByUser = ({
    directions,
    showModal,
    showModalDirection
}) => {
    const classesCheck = useStylesCheckout();
    if (directions.directions.length <= 0) {
        // si no posee direccion !!
        return (
            <Card color="white">
                <CardBody>
                    <h6>Agrega tu primera dirección</h6>
                    <Button
                        color="info"
                        size="sm"
                        outline
                        onClick={showModalDirection}
                    >
                        Agregar dirección
                    </Button>
                    <ModalFormNewDirection isUpdate={false} />
                </CardBody>
            </Card>
        );
    }

    const concatIsExist = selected => {
        const etiqueta = selected.etiqueta || "Sin tipo de dirección";
        if (!selected.hasOwnProperty("concatDirection")) {
            return etiqueta;
        }

        return `${etiqueta} - ${selected.concatDirection}`;
    };

    const concatPathDirection = ({ provincia, departamento, distrito }) => {
        return `${capitalize(distrito)} / ${capitalize(
            provincia
        )} / ${capitalize(departamento)}`;
    };

    const selected = directions.directions.find(d => d.active > 0);

    return (
        <Card color="white">
            <CardBody>
                {!selected ? (
                    <h6>Agrega tu dirección de destino</h6>
                ) : (
                    <>
                        <Info>
                            <h6>Dirección de envío</h6>
                        </Info>
                        <p className={classesCheck.m0}>
                            {concatIsExist(selected)}
                        </p>
                        <p className={classesCheck.m0}>
                            {concatPathDirection(selected.path)}
                        </p>
                        <p className={classesCheck.m0}>{selected.direccion}</p>
                        <p className={classesCheck.m0}>{selected.contacto}</p>
                    </>
                )}
                <Button color="info" size="sm" outline onClick={showModal}>
                    Cambiar dirección
                </Button>
            </CardBody>
            <ModalFormNewDirection isUpdate={selected} />
        </Card>
    );
};
//////------------------internal component fin----------------------////

// component !!
const CheckoutPage = props => {
    const [totalSoles, setTotalSoles] = useState(0);
    const [totalPuntos, setTotalPuntos] = useState(0);
    const [userPoints, setUserPoints] = useState(0); // los puntos que posee el usuario
    const [puntosInput, setPuntosInput] = useState(0); // los puntos que el usuario ingresa para canjear !!
    const [isLoading, setIsLoading] = useState(false);
    const [ShippingConst, setShippingConst] = useState(0);
    const [isLima, setIsLima] = useState("");
    const [itemToSend, setItemToSend] = useState([]);
    const [itemsDontSend, setItemsDontSend] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [terminos, setTerminos] = useState(false);
    const [compareItem, setCompareItem] = useState(true);
    const [itemsChange, setItemsChange] = useState([]); // estos son los items que cambiaron en la bd, con respecto a lo que tiene el usuario cargado!!
    const [showModalChangeProducts, setShowModalChangeProducts] = useState(
        false
    ); // se muestra cuando hay productos que cambiaron, para que regrese al carrito!!
    const [modalErrorCanje, setModalErrorCanje] = useState(false);
    const [textErrorExchange, setTextErrorExchange] = useState("");
    const [isDisabledButton, setIsDisabledButton] = useState(false); // para que cuando se de click en el btn se bloquee al instante !!
    const [showModalOnliCash, setShowModalOnliCash] = useState(false);
    const [cupon, setCupon] = useState("");
    const [activeCupon, setActiveCupon] = useState(null);
    const [isValidCupon, setIsValidCupon] = useState(true);
    const [constoEnvioVales, setConstoEnvioVales] = useState(0);

    const classes = useStyles();
    const classesCheck = useStylesCheckout();
    const classCheked = useStylesCheckd();

    // ============== effets !! ====================
    useEffect(() => {
        handleInitialPointsUser();
        goTop();
    }, []);

    useEffect(() => {
        handleInitialPointsUser();
    }, [props.user.puntos]);

    useEffect(() => {
        calculateTotal();
        // handleSessionKeyVisa();
        setItemToSend(props.items);

        if (compareItem) {
            handleCheckItemsActives();
        }
    }, [props.items]);

    useEffect(() => {
        calculateTotal();
    }, [props.directionsReducer.directions, totalPuntos]);

    useEffect(() => {
        // TRABAJANDO: <-

        if (!!props.directionsReducer.directionActive) {
            const {
                idDepa,
                idProv,
                idDist
            } = props.directionsReducer.directionActive;

            getPathComplete(idDepa, idProv, idDist);
            if ((idDepa == 14 && idProv == 135) || idProv == 129) {
                // prov 129 callao -
                setIsLima("L");
            } else {
                setIsLima("P");
            }
        }
        calculateTotal();
    }, [props.directionsReducer.directionActive]);

    useEffect(() => {
        if (isLima != "L") {
            setItemsDontSend(handleDontSentProduct());
        } else {
            setItemsDontSend([]);
        }
        setItemToSend(handleSentProduct());
    }, [isLima]);

    useEffect(() => {
        if (!!itemsChange.length) {
            setShowModalChangeProducts(true);
        }
    }, [itemsChange]);

    const handleFinalPrice = (product, cant) => {
        if (product.puntosoferta > 0) {
            return product.puntosoferta * cant;
        }

        return product.puntos * cant;
    };

    // chekea si los items estan activos y los elimna de no ser asi
    //REFACTOR:
    const handleCheckItemsActives = async () => {
        const newItems = props.items.map(v => v.id);
        if (!!newItems.length) {
            const activeItems = await getProductsByIds({
                token: props.user.token,
                ids: newItems
            });

            if (activeItems && activeItems.status != "error") {
                const rt = activeItems.map(product => {
                    const newProduct = props.items.find(
                        item => item.id == product.id
                    );

                    if (!!newProduct) {
                        newProduct.precioFinal = handleFinalPrice(
                            product,
                            newProduct.cantidad
                        );
                        newProduct.costoenvio = Number(
                            numberFormat(product.costoenvio)
                        );
                        newProduct.puntos =
                            product.puntosoferta > 0
                                ? product.puntosoferta
                                : product.puntos;
                        newProduct.precio =
                            product.preciooferta > 0
                                ? product.preciooferta
                                : product.precio;
                        newProduct.costoenviovale =
                            product?.costoenviovale || 0;
                        return newProduct;
                    }
                });

                setCompareItem(false);
                setItemToSend(rt);
                props.setProductsCheckout(rt);
                if (!rt.length) {
                    props.history.replace("/carrito");
                }
            }
        }
    };

    const checkEnvio = ubigeo => {
        return props.items.some(d => d.envio !== ubigeo);
    };

    const goTop = () => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    };

    const handleInitialPointsUser = () => {
        if (props.user.puntos == 0 || !props.user.puntos) return;

        const point_user = parseFloat(
            replaceCharacter(props.user.puntos, ",", "")
        );

        setUserPoints(point_user);

        setPuntosInput(point_user - 1);
    };

    // calcula el total a pagar !!
    const calculateTotal = () => {
        if (!itemToSend.length) return;
        //calcula el total de puntos !!
        let calculateTotalPuntos = numberFormat(
            itemToSend.reduce(handleCalculatePuntos, 0)
        );

        // calcula en total de soles !!
        let calculateTotalSoles = numberFormat(
            itemToSend.reduce(handleCalculateSoles, 0)
        );

        let calculateDeliveryItem = itemToSend.reduce(
            handleCalculateDeliberyItem,
            0
        );

        if (props.items.some(product => product.idcategoria === 19)) {
            const calcucloCostoenvioVales = itemToSend
                .filter(item => item.idcategoria === 19)
                .reduce(handleCalculoCostoEnvioValesReduce, 0);

            setConstoEnvioVales(calcucloCostoenvioVales);
        }

        if (!!props.directionsReducer.directions.length) {
            const selected = props.directionsReducer.directions.find(
                d => d.active > 0
            );

            if (!!selected) {
                if (!!Object.keys(selected).length) {
                    const directionValue = !!selected.valor ? selected.valor : selected.path.valor
                    let costoEnvio = directionValue + calculateDeliveryItem;

                    if (!costoEnvio) {
                        return;
                    }

                    if (
                        props.items.every(
                            products => products.idcategoria === 19
                        )
                    ) {
                        costoEnvio = 0;
                    }
                    let descuentoCupon = 0;
                    if (!!activeCupon && activeCupon.monto) {
                        descuentoCupon = activeCupon.monto;
                    }

                    setShippingConst(costoEnvio);
                    const currentCostoEnvio = costoEnvio + constoEnvioVales;
                    calculateTotalPuntos = handleSumDelivery(
                        calculateTotalPuntos,
                        currentCostoEnvio,
                        descuentoCupon * 33
                    );

                    calculateTotalSoles = handleSumDelivery(
                        calculateTotalSoles,
                        currentCostoEnvio / 33,
                        descuentoCupon
                    );
                }
            }

            setTotalPuntos(calculateTotalPuntos);

            setTotalSoles(calculateTotalSoles);

            handleInputPointUser(calculateTotalPuntos);
        }
    };

    const handleInputPointUser = puntos => {
        let total_puntos = parseFloat(replaceCharacter(puntosInput, ",", ""));
        if (total_puntos == "NaN" || total_puntos <= 0 || !total_puntos) {
            total_puntos = 0;
        }

        puntos = parseFloat(replaceCharacter(puntos, ",", ""));

        if (puntosInput > puntos) {
            total_puntos = puntos;
        }

        setPuntosInput(total_puntos);
    };

    const handleSumDelivery = (total, delivery, montoCupon) => {
        let calculate = parseFloat(total.replace(",", "")) + delivery;
        calculate = calculate - montoCupon;

        return numberFormat(calculate);
    };

    const handleCalculateDeliberyItem = (acc, el) => {
        return acc + el.costoenvio * el.cantidad;
    };

    const handleCalculoCostoEnvioValesReduce = (acc, el) => {
        const currentPoints = el.puntosOferta > 0 ? el.puntosOferta : el.puntos;
        return acc + currentPoints * (el.costoenviovale / 100) * el.cantidad;
    };

    /** @OJO esta funcion es una [deduce]!!
     * suma y regresa el todal a pagar en puntos !!
     * @param {number} acc El acumulador
     * @param {object} el el objeto del carrito
     */
    const handleCalculatePuntos = (acc, el) => {
        if (!!el.puntosOferta) {
            return acc + el.puntosOferta * el.cantidad;
        }
        return acc + el.puntos * el.cantidad;
    };

    /** @OJO esta funcion es una [deduce]!!
     * suma y regresa el todal a pagar en soles !!
     * @param {number} acc El acumulador
     * @param {object} el el objeto del carrito
     */
    const handleCalculateSoles = (acc, el) => {
        if (!!el.puntosOferta) {
            return acc + el.precioOferta * el.cantidad;
        }
        return acc + el.precio * el.cantidad;
    };

    const handleChangeTotalPoints = e => {
        let { value } = e.target;
        value = !!value.length ? parseFloat(value) : value;

        const userPoint = !!userPoints
            ? parseFloat(replaceCharacter(userPoints, ",", "")) - 1
            : 0;

        let newValue = value;
        if (value > totalPuntos) {
            newValue = totalPuntos;
        } else if (userPoint == 0) {
            newValue = 0;
        } else if (value >= userPoint && userPoint != 0) {
            newValue = userPoint;
        }

        setPuntosInput(newValue);
    };

    const handleCash = () => {
        if (
            !totalPuntos ||
            totalPuntos == 0 ||
            !puntosInput ||
            puntosInput == 0
        ) {
            return totalSoles;
        }
        const puntoTotal = replaceCharacter(totalPuntos, ",", "");
        const puntosUser = replaceCharacter(puntosInput, ",", "");

        const calculo = (puntosUser - puntoTotal) / 33;

        return transforFormatNumber(calculo);
    };

    /**
     * genera el pedido del canje con los datos del carrito
     * @param {String} type recibe el tipo de canje que se esta realizando!!
     */
    const handleGeneratePedido = async tipo_pago => {
        // <- TRABAJANDO:
        setItemToSend(handleSentProduct());
        const {
            user,
            directionsReducer: { directionActive }
        } = props;

        let total_soles = handleCash();

        const data = {
            ok_nucleo: "1",
            token: props.user.token,
            tipo_pago, // total de soles
            // direction
            ...directionActive,
            total_soles: total_soles.replace(",", ""),

            nombres: user.nombres,
            email: user.email,
            costoenvio: ShippingConst,
            total_puntos: puntosInput,
            total_pagar: totalPuntos,
            items: handleSentProduct(),
            cupon,
            constoEnvioVales
        };

        const result = await setCanjeServices(data);

        return result;
    };

    const handleCheckItemsAfterClickSend = async () => {
        const newItems = itemToSend.map(v => v.id);
        if (!!newItems.length) {
            const activeItems = await getProductsByIds({
                token: props.user.token,
                ids: newItems
            });

            if (activeItems && activeItems.status != "error") {
                const result = itemToSend.filter(newItem => {
                    const res = activeItems.find(active => {
                        return active.id == newItem.id;
                    });

                    if (!res) {
                        return newItem;
                    }
                    if (
                        res.puntosoferta > 0 &&
                        res.puntosoferta != newItem.puntos
                    ) {
                        return res;
                    } else if (
                        res.puntosoferta == 0 &&
                        res.puntos != newItem.puntos
                    ) {
                        return res;
                    }
                });

                setItemsChange(result);

                return !!result.length ? true : false;
            }
        } else {
            props.history.replace("/carrito");
            return true;
        }
    };

    // crear una funcion que elimine del carrito todos los que no sean de lima !!
    const handleSetTypeCanje = async () => {
        setIsDisabledButton(true);
        const totalSoles = handleSetStructMount(handleCash());
        const totalPuntos = puntosInput;
        const token = props.user.token;

        removeItemsProvincia();

        const inCorrectItems = await handleCheckItemsAfterClickSend();
        if (inCorrectItems) {
            // si {inCorrectItems} es true, quiere decir que hay errores en los productos!!
            return;
        }

        if (!!!totalPuntos || totalPuntos == 0) {
            // solo soles !!
            // muestra un modal de info para el canje mixto si el usuario acepta pasa al canje !!
            if (!showModalOnliCash) {
                setShowModalOnliCash(true);
                setIsDisabledButton(false);
                return;
            }
            setShowModalOnliCash(true);

            setIsLoading(true);
            let newTotalSoles = totalSoles.includes(",")
                ? totalSoles.replace(",", "")
                : totalSoles;

            props.initialButton(handleSetStructMount(newTotalSoles), token);

            const { response } = await handleGeneratePedido(SOLES);

            await createTramaSoles({
                token,
                id_pedido: response.header.num_pedido
            });

            props.history.replace("/espera", {
                token,
                amount: handleCash(),
                initialAmount: handleSetStructMount(newTotalSoles),
                numPedido: `${response.header.id}${response.header.num_pedido}`,
                numPedidoDB: response.header.num_pedido,
                id: response.header.id
            });
        } else if (totalPuntos >= 0 && totalSoles <= 0) {
            // solo puntos
            setIsLoading(true);

            const { response } = await handleGeneratePedido(PUNTOS);

            const puntos = await handleChangePoints(response.header.num_pedido);
            if (!puntos) {
                // error al canjear los puntos
                setTextErrorExchange(
                    "Error en su canje, intentelo de nuevo en unos minutos"
                );
                setModalErrorCanje(true);
                setIsDisabledButton(false);
                return;
            }

            const up = await updateAuthPoints({
                token,
                num_pedido: response.header.num_pedido,
                num_autorizacion_puntos: puntos.numAuth
            });
            if (up.status != "ok") {
                // setModalErrorCanje(true);
                // setTextErrorExchange("Error en su canje, estamos regresando sus puntos, si los puntos no son regresados, comuniquese con nosotros");
                // const anul = await annulmentPoints({ token, idTransacionVoid: puntos.traceNumber, amount: puntos.consumoPuntos, numPedido: response.header.num_pedido });

                await handleGetPointsUser();
                setIsLoading(false);
                setIsDisabledButton(false);
                return;
            }

            setIsLoading(false);

            props.history.replace("/respuesta", {
                status: up.status,
                request: up
            });
        } else {
            // mixto
            // muestra un modal de info para el canje mixto si el usuario acepta pasa al canje !!
            if (!showModal) {
                setShowModal(true);
                setIsDisabledButton(false);
                return;
            }
            setShowModal(false);

            setIsLoading(true);
            // muestra un modal de info para el canje mixto si el usuario acepta pasa al canje !!

            const {
                response: { header }
            } = await handleGeneratePedido(MIXTO);

            //puntos !!
            const puntos = await handleChangePoints(header.num_pedido);
            if (!puntos) {
                setTextErrorExchange(
                    "Error en su canje, intentelo de nuevo en unos minutos"
                );
                setModalErrorCanje(true);
                setIsDisabledButton(false);
                // error al canjear los puntos
                // regresar los puntos !! <- TRABAJANDO: FALTA ESTA FUNCION!!
                return;
            }

            await createTramaSoles({ token, id_pedido: header.num_pedido });

            const up = await updateAuthPoints({
                token, // trabajando aqui para editar el token y de error!!
                num_pedido: header.num_pedido,
                num_autorizacion_puntos: puntos.numAuth
            });

            if (up.status != "ok") {
                // setModalErrorCanje(true);
                // setTextErrorExchange("Error en su canje, estamos regresando sus puntos, si los puntos no son regresados, comuniquese con nostros");
                // const anul = await annulmentPoints({ token, idTransacionVoid: puntos.traceNumber, amount: puntos.consumoPuntos, numPedido: header.num_pedido });

                await handleGetPointsUser();
                setIsLoading(false);
                setIsDisabledButton(false);
                return;
            }

            // soles !!
            let newTotalSoles = totalSoles.includes(",")
                ? totalSoles.replace(",", "")
                : totalSoles;

            props.initialButton(handleSetStructMount(newTotalSoles), token);

            setIsLoading(false);

            props.history.replace("/espera", {
                amount: handleCash(),
                numPedido: `${header.id}${header.num_pedido}`,
                numPedidoDB: header.num_pedido,
                id: header.id,
                token
            });

            // mixto
        }
    };

    const handleGetPointsUser = async () => {
        const token = props.user.token;
        const { response } = await getPoints({ token });

        props.updatePointsUser(response.dataMap.availablePoints);
    };

    // agrega la estructura que necesita el modal de visa !!
    const handleSetStructMount = mount => {
        if (!mount) return;
        if (!mount.includes(".") && parseFloat(mount) > 0) {
            return (mount += ".00");
        }
        return mount;
    };

    // change points !!
    const handleChangePoints = async numPedido => {
        const { user } = props;
        const data = {
            amount: puntosInput,
            token: user.token,
            numPedido
        };

        const resp = await setCanjePuntos(data);
        if (resp.status == "error") {
            if (resp.message["status"] == 412) {
                setAuthStorage();
                return;
            }
            setIsLoading(false);
        }

        if (!Object.keys(resp.dataMap).length) {
            setIsLoading(false);
            // aqui va, cuando ocurrer un error en el canje de puntos !!
            return null;
        }

        if (resp.dataMap.codError !== "0000000") {
            // error en el canje !!
            setIsLoading(false);
            return null;
        }

        await handleGetPointsUser();

        return {
            numAuth: resp.dataMap.numAutorization,
            consumoPuntos: resp.dataMap.redeemedPoints,
            traceNumber: resp.traceNumber
        };
    };

    const handleSentProduct = () => {
        const hasDirection = props?.directionsReducer?.directions?.find(d => d.active == "1")
        if (isLima !== "L" && !!hasDirection) {
            return props.items.filter(item => item.envio === "P");
        }
        return props.items;
    };

    const handleDontSentProduct = () => {
        return props.items.filter(item => item.envio === "L");
    };

    const handleNoSentItems = () => {
        if (isLima) {
            return <NotSent items={itemsDontSend} />;
        }
    };

    // check de terminos y condiciones!!
    const handleCheck = () => {
        return (
            <FormControlLabel
                classes={{
                    label: classes.label
                }}
                //Acepto recibir vía e-mail Novedades y Promociones
                control={
                    <Checkbox
                        tabIndex={-2}
                        onClick={ev => {
                            setTerminos(!terminos);
                        }}
                        checkedIcon={
                            <Check className={classCheked.checkedIcon} />
                        }
                        icon={<Check className={classCheked.uncheckedIcon} />}
                        classes={{
                            checked: classCheked.checked,
                            root: classCheked.checkRoot
                        }}
                        checked={terminos}
                        inputProps={{
                            name: "terminos"
                        }}
                    />
                }
                label={
                    <span className={classCheked.ffBook}>
                        Acepto los
                        <b className={classCheked.ffBook}>
                            <a
                                href="https://rewardsperuhelp.zendesk.com/hc/es-419/sections/13533082025243-T%C3%A9rminos-y-Condiciones"
                                className={classNames(
                                    classCheked.ffBook,
                                    classesCheck.colorBlue
                                )}
                                target="_black"
                            >
                                {" "}
                                Términos y Condiciones
                            </a>
                        </b>{" "}
                        de {PUNTOS_DELIVERY}
                    </span>
                }
            />
        );
    };

    // boton de canje !!
    const handleCanjearBtn = () => {
        // if (puntosInput < 33) { // @OJO
        //     return (<Tooltip
        //         title="Para canjear ingrese minimo 33 puntos BBVA"
        //         placement="top"
        //         classes={{ tooltip: classes.tooltip }}
        //     >
        //         <Button
        //             color="bbva"
        //             size="lg"
        //             fullWidth
        //             disabled={handleIsActiveButton()}
        //         >
        //             Canjear ahora
        //         </Button>
        //     </Tooltip>)
        // }

        return (
            <>
                {handleCheck()}
                <Button
                    color="bbva"
                    size="lg"
                    fullWidth
                    onClick={handleSetTypeCanje}
                    disabled={handleIsActiveButton()}
                >
                    Confirmar Pedido
                </Button>
            </>
        );
    };

    const handleCloseModalChangeProduct = () => {
        setShowModalChangeProducts(false);
        props.history.replace("/carrito", null);
    };

    // validacion del disable del btn de canjear !!
    const handleIsActiveButton = () => {
        const hasItems = handleSentProduct();
        return isLoading || !directionActive || !terminos || isDisabledButton || !hasItems.length;
    };

    const handleGoProfile = () => {
        props.history.push("/perfil");
    };

    // ============= cupon ============= //
    /**
     * Los cupones tiene su valor en soles!!
     */
    const handleChangeCupon = ({ target }) => {
        if (!isValidCupon) {
            setIsValidCupon(true);
        }
        setCupon(target.value);
    };

    const handleSearchCupon = async () => {
        if (!cupon || cupon.length < 3) return;
        const { token } = props.user;
        setIsLoading(true);
        const result = await searchCupon(cupon, token);

        setIsValidCupon(!!result);
        setActiveCupon(result);
        setIsLoading(false);
    };

    const handleDeleteCupon = () => {
        setActiveCupon(null);
        setCupon("");
    };

    const handleShowModalDirection = () => {
        const { directionsReducer, selectEditDirection, shoModalFormDirections } = props
        if ( !!directionsReducer.directionActive ) {
            selectEditDirection(directionsReducer.directionActive)
        }
        shoModalFormDirections()
    }

    const { directionActive } = props.directionsReducer;

    return (
        <WrapperMovile>
            <div
                className={classNames(
                    classesCheck.mainMarginTop,
                    classesCheck.heigthSizeContainerCheckout,
                    props.headerReducer.isMobile
                        ? classesCheck.marginContainerCheckoutMobile
                        : ""
                )}
            >
                <div
                    className={classNames(
                        classesCheck.container,
                        classesCheck.resetPd,
                        classes.mt4,
                        classesCheck.paddingHorizontal5
                    )}
                >
                    <ModalGericInfo
                        showModal={showModal}
                        onClose={!showModal}
                        title="Confirma tu pedido de Puntos más Soles"
                        classContent={classes.pt0}
                        classTitle={classes.textCenter}
                        noButtonClose
                        classFooter={classes.containerFotterModal}
                        butonAction={{
                            title: "Ir a pagar",
                            click: () => handleSetTypeCanje()
                        }}
                        buttonClose={{
                            title: "Modificar",
                            click: () => setShowModal(false)
                        }}
                    >
                        <p className={classes.mb0}>
                            Estás a punto de utilizar{" "}
                            <Info> {puntosInput} Puntos</Info> en un canje
                            mixto.
                        </p>
                        <p className={classes.mb0}>
                            A continuación se mostrará una ventana de pago
                            seguro donde debes colocar los datos de la Tarjeta
                            BBVA que usarás para pagar la diferencia de{" "}
                            <Info>S/{handleCash()}</Info> para culminar tu
                            pedido.
                        </p>
                    </ModalGericInfo>

                    <ModalGericInfo
                        showModal={showModalOnliCash}
                        closeModal={() => setShowModalOnliCash(false)}
                        title="Estas a un paso de comprar solo con soles"
                        classContent={classes.pt0}
                        classTitle={classes.textCenter}
                        noButtonClose
                        classFooter={classes.containerFotterModal}
                        butonAction={{
                            title: "Ir a pagar",
                            click: () => handleSetTypeCanje()
                        }}
                        buttonClose={{
                            title: "Volver",
                            click: () => setShowModalOnliCash(false)
                        }}
                    >
                        <p className={classes.mb0}>
                            A continuación se mostrará una ventana de pago
                            seguro donde debes colocar los datos de tu Tarjeta
                            de crédito o débito BBVA que usarás para pagar{" "}
                            <Info>S/{handleCash()}.</Info>
                        </p>
                        <p className={classes.mb0}>
                            <span>Si lo que deseas es canjear tus Puntos BBVA, </span> 
                            haz clic en el botón <span>"Volver" </span> y escribirnos por el chat web o 
                            al correo puntosdelivery@rewardsperu.com solicitand
                            o la Sincronización de tus Puntos BBVA, indicando nombre 
                            completo y DNI.
                        </p>
                    </ModalGericInfo>

                    <ModalGericInfo
                        showModal={showModalChangeProducts}
                        onClose={!showModalChangeProducts}
                        title="¡Upss!, Algo no anda bien."
                        classContent={classes.pt0}
                        classTitle={classes.textCenter}
                        noButtonClose
                        classFooter={classes.containerFotterModal}
                        butonAction={{
                            title: "Revisar bolsa.",
                            click: () => handleCloseModalChangeProduct()
                        }}
                    >
                        <p className={classes.mb0}>
                            En estos momentos tenemos algunos inconvenientes,
                            con alguno de sus productos.
                        </p>
                    </ModalGericInfo>

                    <ModalGericInfo
                        showModal={modalErrorCanje}
                        onClose={!modalErrorCanje}
                        title="¡Upss!, Algo no anda bien."
                        classContent={classes.pt0}
                        classTitle={classes.textCenter}
                        noButtonClose
                        classFooter={classes.containerFotterModal}
                        butonAction={{
                            title: "Cerrar",
                            click: () => setModalErrorCanje(false)
                        }}
                    >
                        <p className={classes.mb0}>{textErrorExchange}</p>
                    </ModalGericInfo>

                    {/* <ListDirections initialRequesDirection /> */}
                    <ModalLoader showModal={isLoading} />
                    <GridContainer className={classNames(classes.mt4)}>
                        <GridItem md={6} sm={6} xs={12}>
                            <h3
                                className={classNames(
                                    classesCheck.title,
                                    classesCheck.textCenter
                                )}
                            >
                                ¿A dónde enviamos tu pedido?
                            </h3>
                            <DirectionActiveByUser
                                directions={props.directionsReducer}
                                showModal={handleShowModalDirection}
                                showModalDirection={
                                    handleShowModalDirection
                                }
                            />
                            {/* // se muestra el boton de canjear cunado no esta en movile */}

                            {!props.headerReducer.isMobile &&
                                handleCanjearBtn()}
                        </GridItem>

                        <GridItem md={6} sm={6} xs={12}>
                            {handleNoSentItems()}

                            <Card color="white">
                                <CardBody>
                                    <div
                                        className={classNames(
                                            classesCheck.containerCard,
                                            classesCheck.borderBt,
                                            classesCheck.centerVertica
                                        )}
                                    >
                                        <Info>
                                            <h6
                                                className={classNames(
                                                    classes.cardCategory,
                                                    classesCheck.SizeH3
                                                )}
                                            >
                                                Tu pedido
                                            </h6>
                                        </Info>

                                        <h6
                                            className={
                                                classesCheck.cardCategory
                                            }
                                        >
                                            Total: {totalPuntos} Puntos
                                            <h6
                                                className={classNames(
                                                    classesCheck.cardCategory,
                                                    classesCheck.textR
                                                )}
                                            >
                                                o S/{totalSoles}{" "}
                                            </h6>
                                        </h6>
                                    </div>

                                    <ItemsCard
                                        data={handleSentProduct()}
                                        directions={props.directionsReducer}
                                        calculate={calculateTotal}
                                        costoEnvio={ShippingConst}
                                        constoEnvioVales={constoEnvioVales}
                                    />
                                </CardBody>
                                <CardFooter
                                    className={classNames(
                                        classesCheck.cardFooter,
                                        classesCheck.column
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            classesCheck.containerCard,
                                            classesCheck.row,
                                            classesCheck.centerVertica
                                        )}
                                    >
                                        <div>Cupón</div>
                                        <div
                                            className={
                                                classesCheck.centerVertica
                                            }
                                        >
                                            {!activeCupon ? (
                                                <>
                                                    <div
                                                        className={
                                                            classesCheck.boxCupon
                                                        }
                                                    >
                                                        <CustomInput
                                                            labelText="Código de cupón"
                                                            formControlProps={{
                                                                fullWidth: false,
                                                                className:
                                                                    classesCheck.m0
                                                            }}
                                                            error={
                                                                !isValidCupon
                                                            }
                                                            inputProps={{
                                                                type: "text",
                                                                autocomplete:
                                                                    "off",
                                                                name: "cupon",
                                                                onChange: handleChangeCupon,
                                                                value: cupon,
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <Icon
                                                                            className={
                                                                                classes.inputIconsColor
                                                                            }
                                                                        >
                                                                            input
                                                                        </Icon>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        {!isValidCupon && (
                                                            <Danger
                                                                className={
                                                                    classesCheck.errorCupon
                                                                }
                                                            >
                                                                El cupón no es
                                                                valido
                                                            </Danger>
                                                        )}
                                                    </div>
                                                    <Button
                                                        size="sm"
                                                        outline
                                                        onClick={
                                                            handleSearchCupon
                                                        }
                                                        // className={classesCheck.btnCupon}
                                                    >
                                                        Aplicar
                                                    </Button>
                                                </>
                                            ) : (
                                                <div
                                                    className={
                                                        classesCheck.boxCuponActivo
                                                    }
                                                >
                                                    <Tooltip
                                                        title={`Descuento del cupón S/${
                                                            activeCupon.monto
                                                        } o por ${activeCupon.monto *
                                                            33} puntos BBVA`}
                                                        placement="left"
                                                    >
                                                        <p
                                                            className={
                                                                classesCheck.infoTex
                                                            }
                                                        >
                                                            {activeCupon.codigo}
                                                        </p>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Elimina el cupón activo"
                                                        placement="left"
                                                        classes={
                                                            {
                                                                // tooltip: classesToolTip.tooltip
                                                            }
                                                        }
                                                    >
                                                        <Button
                                                            justIcon
                                                            simple
                                                            onClick={() =>
                                                                handleDeleteCupon()
                                                            }
                                                        >
                                                            <SvgIcon
                                                                desc="icono-delete"
                                                                fill="#f44336"
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            classesCheck.containerCard,
                                            classesCheck.row,
                                            classesCheck.flex
                                        )}
                                    >
                                        <div>Total:</div>
                                        <div>
                                            <Info>
                                                <b>{totalPuntos} Puntos</b>
                                            </Info>
                                            <p
                                                className={classNames(
                                                    classesCheck.m0,
                                                    classesCheck.textR
                                                )}
                                            >
                                                <span
                                                    className={
                                                        classesCheck.fz14
                                                    }
                                                >
                                                    {" "}
                                                    o S/{totalSoles}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>

                            {/* // calculadora */}

                            {/* <Calculator 
                                onChange={(value) => setPuntosInput(value)}
                                userPoints={userPoints}
                                totalPuntos={totalPuntos}
                            /> */}

                            <Card color="info">
                                <CardBody color>
                                    <div
                                        className={classesCheck.containerTotal}
                                    >
                                        <div
                                            className={classesCheck.displayFlex}
                                        >
                                            <Timeline></Timeline>
                                            <p
                                                className={classNames(
                                                    classesCheck.pl05,
                                                    classes.lightWhite
                                                )}
                                            >
                                                ¿Cuanto pagarás con tu tarjeta?
                                            </p>
                                        </div>
                                        <div
                                            className={classesCheck.displayFlex}
                                        >
                                            <p
                                                className={classNames(
                                                    classes.textWhite,
                                                    classesCheck.pl05,
                                                    classesCheck.m0
                                                )}
                                            >
                                                Ingresa los Puntos BBVA que
                                                utilizaras para calcular el
                                                saldo a pagar con tu tarjeta.
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                classesCheck.containerCalculate
                                            }
                                        >
                                            <div
                                                className={
                                                    classesCheck.totalTextLeft
                                                }
                                            >
                                                <Tune></Tune>
                                                <p
                                                    className={classNames(
                                                        classesCheck.pl05,
                                                        classesCheck.m0,
                                                        classes.lightWhite
                                                    )}
                                                >
                                                    Utilizar
                                                </p>
                                            </div>
                                            <div>
                                                <CustomInput
                                                    id="total_puntos"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        className:
                                                            classesCheck.colorWhite,
                                                        placeholder:
                                                            "Total puntos",
                                                        type: "text",
                                                        name: "total_puntos",
                                                        onChange: handleChangeTotalPoints,
                                                        value: puntosInput,
                                                        endAdornment: (
                                                            <span
                                                                className={classNames(
                                                                    classes.textWhite,
                                                                    classes.ffBook,
                                                                    classes.fz12
                                                                )}
                                                            >
                                                                Puntos
                                                            </span>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={classNames(
                                                classesCheck.containerCalculate,
                                                classesCheck.pdb27
                                            )}
                                        >
                                            <div
                                                className={
                                                    classesCheck.totalTextLeft
                                                }
                                            >
                                                <CreditCard></CreditCard>
                                                <span
                                                    className={classNames(
                                                        classes.lightWhite,
                                                        classes.ffBook,
                                                        classesCheck.pl05
                                                    )}
                                                >
                                                    Paga con tarjeta
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className={classes.ffBook}
                                                >
                                                    {"S/" + handleCash()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* // se muestra el boton de canjear cunado no esta en movile */}
                            {props.headerReducer.isMobile && handleCanjearBtn()}
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </WrapperMovile>
    );
};

const mapStateToProps = ({
    carReducer,
    directionsReducer,
    userReducer,
    headerReducer
}) => {
    return {
        ...carReducer,
        directionsReducer,
        ...userReducer,
        headerReducer
    };
};

const mapDispatchToProps = {
    ...modalActions,
    ...visaCashActions,
    ...directionsUserActions,
    ...carActions,
    ...userActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPage);
