export const PUNTOS_BBVA_LINK =
    "https://www.bbva.pe/personas/productos/tarjetas/credito/programa-de-recompensas/puntos-vida.html";

export const CONTACTANOS = `Si tienes dudas o consultas de como canjear tus Puntos BBVA. 
Haz clic en el chat de la pestaña inferior de tu pantalla, déjanos un mensaje y te contactaremos.

También puedes escribirnos a puntosdelivery@rewardsperu.com

    Para consultas de acumulación de Puntos u otros productos BBVA, comunícate a tu Banca por Teléfono BBVA al 01 595 0000.
`;

export const TERMINOS_CONDICIONES = `Este documento describe los Términos y Condiciones Generales (los "Términos y Condiciones Generales") aplicables al acceso y uso de los servicios ofrecidos por BBVA - Puntos Delivery dentro del sitio www.puntosdelivery.com (el "Sitio").

En la página web de Puntos Delivery, se ofrecen productos de la empresa Rewards (SOCIO).

El sitio Web de Puntos Delivery apunta a exhibir imágenes de productos con la mayor precisión posible. No obstante, no podemos garantizar el stock ni el color que usted ve coincida con el color del producto, ya que la visualización del color depende, en parte, del monitor que utilice.

Proceso de Registro para canjes con Puntos BBVA.
Puedes Registrarte directamente en nuestra web o desde tu Banca por Internet de BBVA, para lo cual deberás realizar los siguientes pasos: 

1. Ingresa a Banca por Internet de BBVA, digita el número de tu Documento de identidad registrado, tu contraseña y haz click en "Ingresar".
2. En la pantalla de Bienvenida, haz click en el botón del menu superior de "Tarjetas", y luego en el botón de "Canjea tus Puntos" .
3. Ingresa la clave Token que llegará a tu teléfono celular.
4. Dale click al botón "acepta" para ingresar a la web de Puntos Delivery
5. Llena el formulario de registro para Puntos Delivery, aquí deberás llenar los datos personales obligatorios, leer y aceptar el reglamento del Programa BBVA, y por último hacer click en "Registrarse".
6. Aparecerá un nuevo mensaje de Registro Finalizado.

Datos Personales.
Para adquirir los productos ofrecidos en el Sitio, los Usuarios Registrados deberán facilitar determinados datos de carácter personal. Su información personal se procesa y almacena en servidores dedicados, los cuales cumplen con los estándares de seguridad y protección tanto física como tecnológica.
Asimismo, de conformidad con la Ley Nº 29733, Ley de Protección de Datos Personales y su Reglamento, Usted autoriza de manera expresa al BBVA (el Banco), con domicilio principal en Av. República de Panamá 3055 San Isidro, Lima, para que directamente o a través de un tercero que éste designe, en territorio nacional o extranjero, proceda: i) a la recopilación, registro, organización, conservación, elaboración, modificación, almacenamiento, extracción, consulta, bloqueo, supresión, transferencia y uso de los datos personales que usted haya entregado o pudiera entregar al Banco, por medio de cualquier canal y a través de cualquier soporte, ii)a completar la mencionada información mediante datos proporcionados por empresas públicas o privadas; para que forme parte de una base de datos. Esta información se mantendrá en esta base indefinidamente mientras sean útiles para que el Banco pueda ofrecer sus productos y/o servicios en forma directa, a través de terceros o mediante asociaciones comerciales y enviarle información y publicidad relativa al Banco o sus subsidiarias. Usted podrá revocar su consentimiento o ejercer los otros derechos que corresponden por ley acercándose a cualquier oficina del Banco a nivel nacional.

Políticas de compra.
Se podrán realizar canjes en el Sitio tanto con Puntos como con Puntos más efectivo con cargo a la Tarjeta de crédito o débito (canje mixto) asi como compras solo con Tarjeta de crédito o débito. para los Canjes Mixtos la cantidad de Puntos Vida mínima requerida es el 10% del valor total del producto. Para el pago de los soles sólo son válidas las tarjetas Visa.

Políticas de entrega.
Para el caso de canje de productos de "Puntos Delivery – Lima" el plazo de entrega será de cinco (5) días útiles a partir de la fecha de canje.

El horario de entrega es de Lunes a Viernes de 09:00am a 06:00pm y Sábados de 09:00 a 01:00pm (Los días feriados no se realizaran entregas).

El área de cobertura de entrega contempla el área de Lima metropolitana y Callao.

Se aplicará en caso de visitas fallidas, como consecuencia de información incorrecta o equívoca por parte del cliente (la cual se podrá corroborar con el formulario de Canje registrado por el cliente).Los canjes errados por parte del cliente tendrán un cargo de 330 puntos por la gestión y servicio de recojo de los productos. De igual forma el recargo se aplicará en caso de visitas fallidas, como consecuencia de información incorrecta, imprecisa, incompleta o equívoca por parte del cliente (la cual se podrá corroborar en el formulario de Canje registrado por el cliente, que se completa en la web del programa vida al momento de efectuar el canje, como parte del proceso del mismo). También se considerarán visitas fallidas la falta de presencia de la persona de contacto. También se considerarán visitas fallidas la falta de presencia de la persona de contacto.

Para el caso de canje de productos de "Puntos Delivery – Provincia" El plazo de entrega de los productos será de ocho (8) días útiles a partir de la fecha de canje.

Se aplicaran costos de envío al momento del canje de acuerdo a la dirección consignada por el cliente.

Políticas de devolución y cancelación de productos.
Los canjes realizados podrán ser anulados por el cliente únicamente dentro del día de realizado el canje, comunicandose deirectamente al 700 3190 o enviando un correo a puntosdelivery@rewardsperu.com Puntos Delivery.

Si el producto presentase fallas de origen o fabricación, en un periodo no mayor a las veinticuatro (24) horas de su recepción, el cliente tendrá la opción de cambio del producto o la anulación del canje, siempre y cuando lo haya notificado a través del correo store@rewatdsperu.com, en estos casos el equivalente en soles a sus Puntos vida serán abonados a la cuenta que indique el cliente. (según el tipo de canje solicitado).

Si el producto presenta mal funcionamiento y/o fallas a partir de las veinticuatro (24) horas posteriores a su recepción, el cliente debe hacer uso de la garantía que acompaña a cada producto derivándose al servicio técnico correspondiente.

Si existiera algún error en el canje, el cliente deberá comunicarlo a través del chat en línea o al correo puntosdelivery@rewardsperu.com el mismo día de realizado el canje para informarlo.

Si este error fuera por parte del cliente o de la plataforma de servicios luego de recibido el producto, éste deberá ser comunicado antes de las veinticuatro (24) horas posteriores a su recepción y si el producto se encuentra en perfectas condiciones de empaque original sellado(*), se podrá realizar el cambio de este por otro producto; siempre y cuando sea por un puntaje similar o mayor, utilizándose para este último caso los puntos adicionales. En ningún caso se aceptará la anulación del canje.

Los productos que se presentan en las comunicaciones correspondientes a la Web de Puntos Delivery y comunicaciones del Programa están sujetos a stock.

(*) El cambio de producto no se aplica a productos médicos, perfumes, licores y suministros tecnológicos que se encuentren en empaques abiertos y deteriorados por el cliente, entiéndase "cliente" incluso a las personas que recepciona los productos.

Políticas de recepción de reclamos y consultas
Si existiera algún reclamo o consulta, el cliente deberá comunicarlo a través del correo a puntosdelivery@rewardsperu.com para informarlo, el cual será respondido en un plazo no mayor a 48 horas (solo días útiles) o a través del teléfono 700 3190 de Lunes a Viernes de 9:00am a 5:30pm.

Al aceptar los términos y Condiciones del sitio Puntos Delivery,
                    afirmo ser mayor de edad (mayor de 18 años), haber leído y estar
                    de acuerdo con el Reglamento de Puntos BBVA y todos los puntos
                    declarados.
`;

export const PREGUNTAS_FRECUENTES = [
    {
        title: "¿Cómo puedo utilizar mis Puntos BBVA?",
        parrafo: `En puntosdelivery.com encontrarás una selección de más de 500 productos, para que elijas el que más te guste y canjees en línea desde la comodidad de tu hogar u oficina solo ingresando a la web de BBVA Puntos Delivery.`
    },
    {
        title: "¿Puedo comprar si no tengo Puntos BBVA?",
        parrafo: `Si, puedes comprar registrando tu usuario y pagando con cualqueir Tarjeta de Crédito y/o Débito de BBVA.`
    },
    {
        title: "¿Los Pedidos pueden ser mixtos: Puntos más Soles?",
        parrafo: `Si, nuestras alternativas de canje son flexibles, estos canjes te permiten elegir entre canjear con solo con Puntos, Puntos más Soles, o comprar solo con soles con cargo a una de tus tarjetas de crédito o débito del BBVA, pudiendo seleccionar antes de confirmar tu pedido la cantidad de Puntos y soles que deseas utilizar.`
    },
    {
        title:
            "¿Los canjes mixtos de puntos más efectivo se pueden realizar con tarjetas de otras entidades bancarias?",
        parrafo: `En los canjes mixtos el pago del monto de efectivo deberá ser cancelado necesariamente con las Tarjetas de Crédito y/o Débito de BBVA.`
    },
    {
        title:
            "¿Que sucede si hago una compra solo con soles o Puntos más soles pero no procesan los soles o no tengo mi tarjeta a la mano?",
        parrafo: `En estos casos si es un pedido de Puntos más soles, primero se procesa los Puntos, y dado que una vez canjeados no pueden ser devueltos, un asesor te contactará para enviar un link de pago posterior para el pago de los soles faltantes, culmines con tu compra o darte las alternativas posibles segun el caso, recuerda que siempre puedes contactarnos a nuestro chat en línea o dejarnos un mensaje.`
    },
    {
        title: "¿Qué puedo canjear o comprar en línea con mis Puntos BBVA ?",
        parrafo: `Puedes seleccionar más de 500 productos de distintas categorías en la web de Puntos Delivery asi como vales virtuales de consumo`
    },
    {
        title: "¿Cómo obtengo un usuario y contraseña para comprar o canjer?",
        parrafo: `Es fácil solo debes registrarte en puntosdelivery.com haciendo clic en el botón de "Ingresar" y luego en la opcion de "Regístrate Aquí" Ingresa tus datos sobre los campos requeridos y crea tu contraseña. Para finalizar deberás hacer clic en el botón de "Ingresar". colocar tipo, número de documento y 
         contraseña que acabas de crear para comenzar a agregar tus productos a la bolsa de pedidos. Recuerda que siempre puedes escribirnos al chat en línea de esta web y te ayudaremos. `
    },
    {
        title: "¿Que hago si no funciona mi usuario y contraseña?",
        parrafo: `Puedes dejarnos un mensaje en nuestro chat en línea o envíanos un correo a puntosdelivery@rewardsperu.com con tu nombre completo, teléfono y documento de identidad. Nosotros te contactaremos para ayudarte a comprar o canjear tus puntos BBVA.`
    },
    {
        title:
            "¿Qué debo hacer si olvide mi contraseña para canjear o comprar en la web de BBVA Puntos Delivery?",
        parrafo: `Dale click al botón "Ingresar" y luego a la opcion de "Olvidé mi Contraseña", sigue los pasos indicados. Tambien puedes dejarnos un mensaje 
        en nuestro chat en línea o envianos un correo a puntosdelivery@rewardsperu.com 
        con el asunto: "Ovlidé mi Contraseña"
            En el correo escríbenos el Nombre completo del Titular de la Cuenta, número de documento de identidad, teléfono celular y te contactaremos.`
    },
    {
        title: "¿Que hago si al ingresar no visualizo mis puntos BBVA?",
        parrafo: `Debes ingresar a tu perfil, haciendo click en tu nombre de la barra superior y registrar tu tarjeta BBVA que acumula Puntos, así mismo asegúratete que la tarjeta se encuentre prendida en tu app de BBVA Banca Móvil.`
    },
    {
        title: "¿Después de cuantos días recibiré mis productos canjeados?",
        parrafo: `Si canjeaste productos fisicos deberás recibirlos en un plazo no mayor a 5 días habiles para Lima metropolitana y Callao. Para el caso de envíos a Porvincias deberas recibirlos en un plaz ode entre 10 y 15 días hábiles.`
    },
    {
        title:
            "¿Después de cuantos días recibiré mis vales virtuales canjeados?",
        parrafo: `Si canjeaste productos virtuales como E-vales los recibiras en el correo consignado en tu perfil de cliente en un plazo no mayor a 48 horas hábiles.`
    },
    {
        title: "¿Qué es Puntos BBVA?",
        parrafo: "Es el programa de lealtad de las Tarjetas de Crédito BBVA ."
    },
    {
        title: "¿Cómo acumulo Puntos BBVA?",
        parrafo: `Cada vez que compres con tu Tarjeta de Cédito BBVA estarás acumulando Puntos.
        No acumulan Puntos BBVA los consumos en casinos, disposición de efectivo, impuestos, servicios públicos, compra de
        deuda, débitos automáticos, pagos realizados a través de Banca por Internet www.bbva.pe y pagos
        que se realicen por el módulo de recaudaciones en las ventanillas de las oficinas. `
    },
    {
        title: "¿Cuantos puntos equivalen a S/1 (1 Sol)?",
        parrafo: `33 Puntos equivalen a S/1 (1 sol), por lo cual si en tu cuenta tienes 3,300 Puntos, estos equivalen a S/100 para que puedas canjear lo que desees, y recuerda que si el producto tiene un valor mayor puedes pagar la diferencia con cualqueir Tarjeta BBVA.`
    },
    {
        title: "¿Cada cuanto vencen mis Puntos BBVA?",
        parrafo:
            "Los Puntos BBVA vencen inmediatamente despues de los 2 años de su acumulación."
    },
    {
        title: "¿La acumulación de Puntos BBVA es por tarjeta o por cliente?",
        parrafo: "La acumulación es por cliente (se hace una sola bolsa)."
    },
    {
        title: "¿Las tarjetas adicionales acumulan Puntos BBVA?",
        parrafo:
            "Sí, las tarjetas adicionales acumulan Puntos BBVA para el titular de la tarjeta."
    },
    {
        title: "¿Las tarjetas adicionales pueden canjear Puntos BBVA?",
        parrafo:
            "No, las tarjetas adicionales no pueden canjear Puntos BBVA, Sólo el cliente titular puede utilizar los Puntos BBVA, siempre que su tarjeta se encuentre sin ningún tipo de bloqueo."
    },
    {
        title: "¿Cómo puedo saber mis Puntos BBVA acumulados?",
        parrafo:
            "Puedes ver tus Puntos BBVA acumulados en tu Estado de Cuenta de tu Tarjeta de Crédito, entrando a tu Banca por Internet en bbva.pe, en tu app de Banca Móvíl o llamando a tu Banca por Teléfono al 01 595-0000."
    },
    {
        title: "¿Quiénes pueden canjear Puntos BBVA?",
        parrafo:
            "Los Puntos BBVA solo pueden ser canjeados por el titular de la tarjeta."
    },
    {
        title: "¿Los Puntos BBVA se pueden perder?",
        parrafo: `Si, los Puntos BBVA se pierden si la tarjeta es cancelada, bloqueada por mora, si no es usada en compras por POS en un periodo mayor a 6 meses. 
        También debe tener en cuenta que los Puntos BBVA vencen después de 2 años de su acumulación.`
    }
];

export const TERMINOS_CONDICIONES_2 = [
    {
        title: "",
        parrafos: [
            `El objeto de los presentes Términos y condiciones es regular el acceso y uso al Sitio Web https://puntosdelivery.com  (en adelante el Sitio Web)
            El Sitio Web es Administrada y de titularidad de la empresa Rewards Peru SAC (en adelante Rewards) quien se reserva la facultad de modificar en cualquier momento y sin previo aviso la presentación, la funcionalidad, 
            los productos, y los servicios que contenienen el Sitio Web; en tal sentido, el cliente reconoce y acepta que web en cualquier momento podrá interrumpir, desactivar, modificar o cancelar
            cualquiera de los elementos que componen el Sitio Web el acceso a los mismos.`,

            `Es requisito para canjear en el Sitio Web, la aceptación de los Términos y Condiciones descritos a continuación.
            Cualquier persona que realice una transacción en el SItio Web, declara y reconoce, por el hecho de efectuar el canje, que conoce y acepta todos y cada uno de los Términos y Condiciones descritos 
            a continuación. Se entenderán conocidos y aceptados los Términos y Condiciones por el solo hecho del registro y/o el canje de productos.`,

            `Rewards se reserva el derecho de actualizar y/o modificar los Términos y Condiciones que detallamos a continuación en cualquier momento, sin previo aviso. 
            Por esta razón recomendamos revisar los Términos y Condiciones cada vez que utilice el Sitio Web. 
            Los consumidores quedarán sujetos a los Términos y Condiciones del Sitio Web, en el momento en el realicen su solicitud de canje.`,

            `El Sitio Web apunta a exhibir imágenes de productos con la mayor precisión posible. No obstante, no podemos garantizar el color que usted visualice en su pantalla coincida 
            con el del producto a entregar, ya que la visualización del color depende, en parte, del tipo de pantalla y configuración de color que utilice el usuario.`,

            `Cualquier entrega de productos canjeados a través de este Sitio Web, están limitadas a las condiciones territoriales y/o restricciones legales que puedan imponer las autoridades. 
            En caso se deba reprogramar alguna de las entregas programadas por responsabilidad no atribuible al Sitio Web, el equipo correspondiente se comunicará con el cliente para realizar las coordinaciones 
            necesarias.`,

            `Ponemos en conocimiento que dado el Estado de Emergencia (Decreto Supremo N° 044-2020-PCM), las condiciones de despacho y entrega han sido afectadas, pudiendo surgir eventuales retrasos 
            en las fechas programadas para el despacho y entrega de los productos adquiridos.`,

            `Nuestros procesos de manipulación y entrega de productos cumplen con los estándares de salubridad establecidos por las autoridades correspondientes, 
            a fin de disminuir al mínimo la exposición de nuestros colaboradores y clientes; sin perjuicio de ello recomendamos tomar todas las medidas necesarias para una adecuada prevención, 
            desinfectando los productos de manera adecuada en cuanto sean recibidos.`
        ]
    },
    {
        title: "Registro del Cliente para canjes con Puntos BBVA.",
        parrafos: [
            `Para canjear los productos en el Sitio Web, es necesario estar registrado. Puedes registrarte directamente en el Sitio Web haciendo clic en en botón "Ingresar" de la barra superior 
            y luego en el enlace inferior con el texto "Regístrate aquí" y llenar el formulario correspondiente. 
            Posterior a esto recibiras un correo de confirmación de registro y podras ingresar colocando tu tipo, número de documento de identidad y contraseña de acceso es personal e intransferible registrada exclusiva para Puntos Delivery.
            Luego de ingresar visualizaras tu nombre en la parte superior, sin embargo para enlazar tus Puntos BBVA al Sitio Web deberás ir a tu perfil y registrar tu Tarjeta que acumula Puntos BBVA.`,

            `Tambien puedes registrarte ingresando desde tu Banca por Internet de BBVA, y realizar los siguientes pasos
            siguientes pasos:`
        ],
        lista: [
            `Ingresa a Banca por Internet en la web oficial de BBVA (bbva.pe), asegurandote que el sitio sea correcto,  digita el número de tu
            Documento de identidad registrado, tu contraseña de banca por Internet y haz click
            en "Ingresar".`,

            `En la pantalla de Bienvenida, haz click en el botón del menú 
            superior de "Tarjetas", y luego en el botón de "Canjea tus
            Puntos".`,

            `Ingresa la clave Token digital generada en tu Banca Móvil.`,

            `Dale click al botón "acepta" para ingresar a la web de
            Puntos Delivery.`,

            `Llena el formulario de registro correspondiente al Sitio Web Puntos Delivery.`
        ]
    }
];
