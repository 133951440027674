import React, { useEffect, useState } from "react";

// core components
import Accordion from "components/Accordion/Accordion";
import Icon from "@material-ui/core/Icon";
//style
import directionStyle from "../../../assets/jss/material-kit-pro-react/components/directionStyle";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// redux
import { connect } from "react-redux";
import * as directionsUserActions from "../../../redux/actions/directionsUserActions";

import CardDirection from "./CardDirection";
import Spinner from "components/spinner/Spinner";

const useStyles = makeStyles(directionStyle);

// internal component !!
const TitleActive = ({ direction }) => {
    const classes = useStyles();

    if (direction.active == "0") {
        return direction.etiqueta;
    }

    return ( 
        <>
            <Icon className={classes.iconActive}>star</Icon>
            {direction.etiqueta}
        </>
    );
};

// main component
const Directions = ({
    directionsReducer,
    userReducer,
    show = true,
    ...props
}) => {
    const { directions } = directionsReducer;
    const { user } = userReducer;
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (!directions.length) {
            props.getDirectionsUser(user.token);
        }
    }, []);
    

    const handleGenerateAcordeon = () => {
        console.log("handleGenerateAcordeon", directionsReducer)
        if (!directions.length || !directionsReducer.directionActive) return [];

        return [
            {
                title:
                    <TitleActive direction={directionsReducer.directionActive} /> ||
                    "Sin nombre de dirección",
                content: (
                    <CardDirection
                        direction={directionsReducer.directionActive}
                        directionSelected={directionsReducer.directionSelect}
                        selectEditDirection={props.selectEditDirection}
                        selectDirection={value =>
                            props.activeUserDirection(value, user.token)
                        }
                        onClick={props.onClick}
                        removeDirection={props.removeDirection}
                        user={user}
                    />
                )
            }
        ];
    };

    if (directionsReducer.isLoading) {
        return (
            <div className={classes.container}>
                <Spinner />
            </div>
        );
    }

    if (!directions.length) {          return (
            <div className={classes.container}>
                <h4 className={classes.titleNoDirection}>
                    Agrega tu primera dirección
                </h4>
            </div>
        );
    }

    if (!show) {
        return <></>;
    }

    return (
        <div className={classes.containerAccordion}>
            <Accordion
                active={activeTab}
                activeColor="info"
                collapses={handleGenerateAcordeon()}
            />
        </div>
    );
};

const mapStateToProps = ({ userReducer, directionsReducer }) => {
    return {
        userReducer,
        directionsReducer
    };
};

const mapDispatchToProps = {
    ...directionsUserActions
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Directions);
