import React, { useState, useEffect } from "react";
import Directions from "./Directions";

import FormularioDirecciones from "./FormularioDirecciones";
import NavPills from "components/NavPills/NavPills";
import * as directionsUserActions from "../../../redux/actions/directionsUserActions";
import { connect } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import { Box } from "@material-ui/core";
import SvgIcon from "components/Svg/SvgIcon";
import Button from "components/CustomButtons/Button.js";

const ContainerDireciones = props => {
    const [active, setActive] = useState(0);

    useEffect(() => {
        console.log("direccionEdit", active)
        // if (!props.direction.directionActive) {
        //     setActive(1);
        // } else {
        //     setActive(0);
        // }
        // if (!!props.direction.directionActive && active === 0) {
        //     console.log("if active")
        //     setActive(1);
        // }
        // if (!props.direction.directionActive && active === 1) {
        //     console.log("if inactive")
        //     setActive(0);
        // }
    }, [props.direction.directionActive]);

    useEffect(() => {
        if (!props.direction.directions.length ) {
            setActive(1);
        } else if (props.direction.directionActive) {
            setActive(0);
        } else if (!props.direction.directionActive) {
            setActive(1);
        }
    }, [props.direction.directions.length]);

    const handleOnChange = active => {
        const directions = props?.directionsReducer?.directions;
        if (props?.directionsReducer && !!directions.length) {
            props.selectEditDirection(directions[0]);
        }
        setActive(active);
    };

    const handleCreateTabs = () => {
        console.log("handleCreateTabs", active);
        let tabs = [
            {
                tabButton: "Direcciones",
                tapRenderIcon: "icono-home",
                tabContent: (
                    <div style={{ minHeight: "300px" }}>
                        <Directions show={!active} />
                        <div style={{ display: active ? "block" : "none" }}>
                            <FormularioDirecciones />
                        </div>
                    </div>
                )
            }
            // {
            //     tabButton: "Editar Dirección",
            //     tapRenderIcon: "icono-agregar",
            //     tabContent: <FormularioDirecciones />
            // }
        ];

        return tabs;
    };

    const handleClick = () => {
        console.log("handleClick")
        setActive(0);
    };

    const handleToggleClick = () => {
        console.log("click")
        setActive(!active);
    };

    return (
        <>
            <Button
                color="info"
                size="lg"
                style={{ padding: "25px" }}
                onClick={handleClick}
            >
                <Box
                    style={{
                        display: "flex",

                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <SvgIcon
                        desc="icono-home"
                        fill="#F4F4F4"
                        width="20px"
                        height="20px"
                    />
                    <p
                        style={{
                            margin: 0,
                            marginTop: "5px",
                            color: "#F4F4F4",
                            fontFamily: "BentonSansBBVA Medium",
                            fontSize: "12px"
                        }}
                    >
                        Dirección
                    </p>
                </Box>
            </Button>
            <div style={{ minHeight: "300px" }}>
                <Directions show={!active} onClick={handleToggleClick} />
                <div style={{ display: active ? "block" : "none" }}>
                    <FormularioDirecciones onClick={handleToggleClick} />
                </div>
            </div>
            {/* <NavPills
                color="info"
                active={active}
                onChange={handleOnChange}
                tabs={handleCreateTabs()}
            ></NavPills> */}
        </>
    );
};

const mapStateToProps = ({ directionsReducer }) => {
    return {
        directionsReducer
    };
};

const mapDispatchToProps = {
    selectEditDirection: directionsUserActions.selectEditDirection
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContainerDireciones);
