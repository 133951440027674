import {
    DELETE_DIRECTION_USER,
    UPDATE_DIRECTION_USER,
    ADD_DIRECTION_USER,
    GET_DIRECTIONS_USER,
    SELECT_EDIT_DIRECCION,
    REMOVE_SELECT_EDIT_DIRECTION,
    SELECT_DIRECTION,
    IS_LOADING,
    ERROR,
    DIRECTION_ACTIVE
} from "../../types/directionsType";

const INITIAL_STATE = {
    directions: [],
    direccionEdit: null,
    directionSelect: null,
    directionActive: null,
    error: "",
    isLoading: false
};
// tratando de pasar los distritos por redux, pero cada formulario tiene que manejar su estado propio
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, isLoading: true };

        case GET_DIRECTIONS_USER:
            return getDirection(state, action.payload);
        // return { ...state, directions: action.payload, isLoading: false };

        case ADD_DIRECTION_USER:
            return addNewDirection(state, action.payload);

        case SELECT_DIRECTION:
            return { ...state, directionSelect: action.payload };

        case UPDATE_DIRECTION_USER:
            return updateDirection(state, action.payload);

        case SELECT_EDIT_DIRECCION:
            return {
                ...state,
                direccionEdit: action.payload
            };

        case REMOVE_SELECT_EDIT_DIRECTION:
            return { ...state, direccionEdit: null };

        case DELETE_DIRECTION_USER:
            return removeDirection(state, action.payload);

        case DIRECTION_ACTIVE:
            return changeSelectDirection(state, action.payload);

        case ERROR:
            return { ...state, error: action.payload };

        default:
            return { ...state };
    }
};

const changeSelectDirection = (state, direction) => {
    const newState = { ...state };

    deactivateDirection(newState);
    newState.directions.filter(direct => {
        if (direct.id === direction.id) {
            direct.active = "1";
            newState.directionActive = direct;
        }
        return direct;
    });

    return newState;
}

const getDirection = (state, directions) => {
    const newState = { ...state };

    const active = directions.find(d => d.active > 0);

    newState.directions = directions;
    newState.isLoading = false;
    newState.directionActive = active;

    return newState;
};

// add or update by id !!
const addNewDirection = (state, direction) => {
    const newState = { ...state };
    if (direction.active == "1") {
        const index = newState.directions.findIndex(
            direct => direct.active == "1"
        );

        if (index > 0 && !!newState.directions.length) {
            newState.directions[index].active = "0";
        }
        newState.directionActive = direction
    }
    newState.direccionEdit = null;

    newState.directions.push(direction);

    return newState;
};

// uodate direction by id !!
// deprecate !!
const updateDirection = (state, direction) => {
    const newState = { ...state };

    const index = newState.directions.findIndex(
        direct => direct.id === direction.id
    );

    if (direction.active == "1") {
        deactivateDirection(newState);
        newState.directionActive = direction;
    }

    if (index !== -1) {
        newState.directions.splice(index, 1, direction);
    }

    newState.direccionEdit = null;

    return newState;
};

const deactivateDirection = (newState) => {
    let direction = newState.directions.find(d => d.active === "1");
    if (direction) {
        direction.active = "0";
    }
}

// remove direction by id !!
const removeDirection = (state, idDirection) => {
    const newState = { ...state };
    const index = newState.directions.findIndex(
        value => value.id == idDirection
    );
    newState.directionActive = null;
    newState.direccionEdit = null;
    newState.directions.splice(index, 1);
    console.log("removeDirection", newState, state)
    return newState;
};
