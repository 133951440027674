import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// nodejs library that concatenates classes
import classNames from "classnames";

// material-ui icons
import Radio from "@material-ui/core/Radio";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Tooltip from "@material-ui/core/Tooltip";

import Button from "components/CustomButtons/Button.js";

// core components
import Table from "components/Table/Table.js";

//redux
import { connect } from "react-redux";
import * as directionsUserActions from "../../redux/actions/directionsUserActions";
import * as modalActions from "../../redux/actions/modalActions";
import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import Info from "components/Typography/Info";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import { updateActiveDirection } from "services/form/direccionServices";
import ModalFormNewDirection from "components/Modal/ModalFormNewDirection";

// icons !!
import SvgIcon from "components/Svg/SvgIcon";
import { NO_TIPE_DIRECTION } from "config/const/constText";

const useStylesBasic = makeStyles(basicsStyle);

const useStyles = makeStyles(style);
const useStylesModal = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

// component
const ListDirections = ({ directions, user, initialRequesDirection = false, ...props }) => {
    // state
    const [Listdirections, setListdirections] = useState([]);
    const [disabledButtons, setDisabledButtons] = useState(false);

    // cicle life !!
    useEffect(() => {
        if (!directions.directions.length || initialRequesDirection) {
            props.getDirectionsUser(user.token);
        }
    }, []);

    useEffect(() => {
        if (!!directions.directions.length) {
            setListdirections(directions.directions);
            list(); // para que se actualize el listado siempre que suceda un cambio !!
        }
    }, [directions.directions]);

    const classes = useStyles();
    const classesBasic = useStylesBasic();
    const classesModal = useStylesModal();

    const handleHidenModal = () => {
        props.hidenModalDirections();
        setDisabledButtons(false);
    };

    const handleChangeSelect = async direction => {
        if (!Listdirections.length) return;
        const newList = Listdirections.map(direct => {
            if (direct.id === direction.id) {
                direct.active = "1";
            } else {
                direct.active = "0";
            }
            return direct;
        });
        setListdirections(newList);
    };

    // actualiza la direccion activa !!
    const handleSaveDirectionActive = async () => {
        setDisabledButtons(true);
        const select = Listdirections.find(direct => direct.active > 0);
        const result = await props.activeUserDirection(select, user.token);

        setDisabledButtons(false);
        handleHidenModal();
    };

    const handleNewDirection = () => {
        props.selectEditDirection(null);
        props.shoModalFormDirections()
    }

    // REFACTOR: convertir en componente interno !!
    const list = () => {
        const handleSelectdirectionEdit = direction => {
            console.log("edit", direction)
            props.selectEditDirection(direction);
            props.shoModalFormDirections();
        };

        if (!Listdirections.length || !Listdirections) return [];

        return Listdirections.map(direction => {
            return (
                !!direction && [
                    <Tooltip
                        title="Activar Dirección"
                        placement="left"
                        classes={{
                            tooltip: classes.tooltip
                        }}
                    >
                        <Radio
                            checked={direction.active != 0}
                            onChange={() => handleChangeSelect(direction)}
                            value="a"
                            name="radio button enabled"
                            aria-label="A"
                            icon={
                                <FiberManualRecord
                                    className={classesBasic.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                    className={classesBasic.radioChecked}
                                />
                            }
                            classes={{
                                checked: classesBasic.radio,
                                root: classesBasic.radioRoot
                            }}
                        />
                    </Tooltip>,
                    <div>
                        <Info>
                            <h6>{direction.etiqueta || NO_TIPE_DIRECTION}</h6>
                        </Info>
                        <p>{direction.direccion}</p>
                    </div>,
                    <div className={classes.containerButtons}>
                        <Tooltip
                            title="Editar Dirección"
                            placement="left"
                            classes={{
                                tooltip: classes.tooltip
                            }}
                        >
                            <Button
                                color="info"
                                justIcon
                                simple
                                className={classNames(
                                    classes.pd0,
                                    classesModal.pb8
                                )}
                                disabled={disabledButtons}
                                onClick={() =>
                                    handleSelectdirectionEdit(direction)
                                }
                            >
                                <Edit></Edit>
                            </Button>
                        </Tooltip>

                        <Tooltip
                            title="Eliminar Dirección"
                            placement="left"
                            classes={{
                                tooltip: classes.tooltip
                            }}
                        >
                            <Button
                                color="danger"
                                justIcon
                                simple
                                disabled={disabledButtons}
                                className={classes.pd0}
                                onClick={() => {
                                    props.removeDirection(
                                        direction.id,
                                        user.token
                                    )
                                }
                                }
                            >
                                <SvgIcon desc="icono-delete" fill="#f44336" />
                            </Button>
                        </Tooltip>
                    </div>
                ]
            );
        });
    };

    return !directions.directions.length ? null : (
        <Dialog
            classes={{
                root: classesModal.modalRoot,
                paper: classesModal.modal
            }}
            open={props.modal.show}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleHidenModal}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
        >
            <DialogTitle
                id="small-modal-slide-title"
                disableTypography
                className={classNames(
                    classesModal.modalHeader,
                    classesModal.containerHeader
                )}
            >
                <h3>Mis direcciónes</h3>
                <p className={classesModal.subTitle}>Selecciona donde enviaremos tu canje</p>
                <Button
                    simple
                    className={classesModal.floatButtonClose}
                    key="close"
                    aria-label="Close"
                    onClick={handleHidenModal}
                >
                    <SvgIcon desc="icon-close" fill="#000" />
                </Button>
            </DialogTitle>
            <DialogContent
                id="small-modal-slide-description"
                className={classesModal.modalBody}
            >
                <ModalFormNewDirection isUpdate={false} />
                <Table
                    striped
                    tableHead={["", "", ""]}
                    tableData={list()}
                    customCellClasses={[
                        classes.textCenter,
                        classes.textCenter,
                        classes.textRight
                    ]}
                    customClassesForCells={[0, 1, 2]}
                    customHeadCellClasses={[
                        classes.textCenter,
                        classes.textCenter,
                        classes.textRight
                    ]}
                    customHeadClassesForCells={[0, 1, 2]}
                />
            </DialogContent>
            <DialogActions
                className={classNames(
                    classesModal.modalFooter,
                    classesModal.modalFooterCenter,
                    classesModal.footerButtoms)
                }
            >
                <Button
                    onClick={handleNewDirection}
                    disabled={disabledButtons}
                    color="info"
                    className={
                        classesModal.modalSmallFooterFirstButton +
                        " " +
                        classesModal.modalSmallFooterSecondButton
                    }
                >
                    Nueva dirección
                </Button>
                <Button
                    onClick={handleSaveDirectionActive}
                    disabled={disabledButtons}
                    color="info"
                    className={
                        classesModal.modalSmallFooterFirstButton +
                        " " +
                        classesModal.modalSmallFooterSecondButton
                    }
                >
                    Continuar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = ({ directionsReducer, userReducer, ModalsReducer }) => {
    return {
        directions: directionsReducer,
        user: userReducer.user,
        modal: ModalsReducer.directionsModal
    };
};

const mapDispatchToProps = {
    ...directionsUserActions,
    ...modalActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDirections);
